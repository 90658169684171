// Components/Payment/PaymentCancel.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';

const PaymentCancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    toast.info('Payment was cancelled');
    
    // Add a small delay before redirecting
    const timeout = setTimeout(() => {
      navigate('/employer/subscription');
    }, 1500);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
        <FaSpinner className="animate-spin text-4xl text-blue-950 mx-auto mb-4" />
        <h2 className="text-xl font-medium text-gray-900 mb-4">
          Redirecting...
        </h2>
        <p className="text-gray-600">
          Taking you back to the subscription page
        </p>
      </div>
    </div>
  );
};

export default PaymentCancel;