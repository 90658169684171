import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase';
import { toast } from 'react-toastify';
import { FaCalendarTimes, FaEye, FaTrash, FaSync } from 'react-icons/fa';

const ExpiredJobs = () => {
  const [expiredJobs, setExpiredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  
  const db = getDatabase(app);
  const auth = getAuth(app);

  const fetchExpiredJobs = useCallback(async () => {
    try {
      setLoading(true);
      const jobsSnap = await get(ref(db, 'jobs'));
      
      if (!jobsSnap.exists()) {
        setExpiredJobs([]);
        setLoading(false);
        return;
      }
      
      const jobsData = jobsSnap.val();
      const now = new Date();
      const expiredJobsList = [];
      
      // Iterate through all jobs and find expired ones
      Object.keys(jobsData).forEach(employerId => {
        Object.entries(jobsData[employerId]).forEach(([jobId, job]) => {
          // Check if job is expired either by status or by expiration date
          if (
            job.status === 'expired' || 
            (job.expirationDate && new Date(job.expirationDate) < now)
          ) {
            expiredJobsList.push({
              id: jobId,
              employerId,
              ...job,
              // Add calculated fields
              daysExpired: job.expirationDate ? 
                Math.floor((now - new Date(job.expirationDate)) / (1000 * 60 * 60 * 24)) : 
                0,
              isStatusExpired: job.status === 'expired',
              isDateExpired: job.expirationDate && new Date(job.expirationDate) < now
            });
          }
        });
      });
      
      // Sort by expiration date (most recently expired first)
      expiredJobsList.sort((a, b) => {
        if (!a.expirationDate) return 1;
        if (!b.expirationDate) return -1;
        return new Date(b.expirationDate) - new Date(a.expirationDate);
      });
      
      setExpiredJobs(expiredJobsList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching expired jobs:', error);
      toast.error('Error loading expired jobs');
      setLoading(false);
    }
  }, [db]);
  
  const updateJobStatus = async (employerId, jobId, newStatus) => {
    try {
      const jobRef = ref(db, `jobs/${employerId}/${jobId}`);
      await update(jobRef, { 
        status: newStatus,
        updatedAt: new Date().toISOString() 
      });
      
      toast.success(`Job ${newStatus === 'expired' ? 'marked as expired' : 'reactivated'} successfully`);
      fetchExpiredJobs();
    } catch (error) {
      console.error('Error updating job status:', error);
      toast.error('Failed to update job status');
    }
  };
  
  const handleDeleteJob = async (employerId, jobId) => {
    if (window.confirm('Are you sure you want to permanently delete this job?')) {
      try {
        // Create a null object to remove the job
        const jobRef = ref(db, `jobs/${employerId}/${jobId}`);
        await update(jobRef, null);
        
        toast.success('Job deleted successfully');
        fetchExpiredJobs();
      } catch (error) {
        console.error('Error deleting job:', error);
        toast.error('Failed to delete job');
      }
    }
  };
  
  const handleReactivateJob = async (employerId, jobId) => {
    if (window.confirm('Are you sure you want to reactivate this job? This will make it visible to candidates again.')) {
      await updateJobStatus(employerId, jobId, 'approved');
    }
  };
  
  const handleMarkAsExpired = async (employerId, jobId) => {
    if (window.confirm('Are you sure you want to mark this job as expired?')) {
      await updateJobStatus(employerId, jobId, 'expired');
    }
  };
  
  const viewJobDetails = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };
  
  useEffect(() => {
    const checkAdminAccess = async () => {
      if (!auth.currentUser) {
        window.location.href = '/admin-login';
        return;
      }

      try {
        const adminRef = ref(db, `admins/${auth.currentUser.uid}`);
        const snapshot = await get(adminRef);
        
        if (snapshot.exists()) {
          setIsAdmin(true);
          fetchExpiredJobs();
        } else {
          toast.error('Access denied. Admin privileges required.');
          window.location.href = '/admin-login';
        }
      } catch (error) {
        console.error('Error checking admin access:', error);
        window.location.href = '/admin-login';
      }
    };

    checkAdminAccess();
  }, [auth.currentUser, db, fetchExpiredJobs]);

  if (!isAdmin || loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-950"></div>
      </div>
    );
  }

  if (expiredJobs.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6 w-full">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-blue-950">Expired Jobs</h2>
          <a href="/admin/dashboard" className="text-blue-600 hover:text-blue-800">
            Back to Dashboard
          </a>
        </div>
        <div className="p-8 text-center">
          <FaCalendarTimes className="text-5xl text-gray-400 mx-auto mb-4" />
          <p className="text-xl text-gray-500">No expired jobs found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6 w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-blue-950">Expired Jobs ({expiredJobs.length})</h2>
        <a href="/admin/dashboard" className="text-blue-600 hover:text-blue-800">
          Back to Dashboard
        </a>
      </div>
      
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Job Title
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Company
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Expiration Date
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Applications
              </th>
              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {expiredJobs.map((job) => (
              <tr key={job.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{job.jobTitle}</div>
                  <div className="text-sm text-gray-500">{job.parish}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {job.companyName}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {job.expirationDate ? (
                    <div>
                      <div className="text-sm text-gray-900">
                        {new Date(job.expirationDate).toLocaleDateString()}
                      </div>
                      <div className="text-sm text-red-500">
                        Expired {job.daysExpired} day{job.daysExpired !== 1 ? 's' : ''} ago
                      </div>
                    </div>
                  ) : (
                    <span className="text-sm text-gray-500">No date set</span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                    ${job.isStatusExpired ? 'bg-red-100 text-red-800' : 'bg-yellow-100 text-yellow-800'}`}>
                    {job.isStatusExpired ? 'Status: Expired' : 'Date Expired'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {job.applications ? 
                    (typeof job.applications === 'object' ? 
                      Object.keys(job.applications).length : 
                      job.applications) : 
                    0}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => viewJobDetails(job)}
                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                  >
                    <FaEye className="inline mr-1" /> View
                  </button>
                  {!job.isStatusExpired && (
                    <button 
                      onClick={() => handleMarkAsExpired(job.employerId, job.id)}
                      className="text-yellow-600 hover:text-yellow-900 mr-4"
                    >
                      <FaCalendarTimes className="inline mr-1" /> Mark Expired
                    </button>
                  )}
                  {job.isStatusExpired && (
                    <button 
                      onClick={() => handleReactivateJob(job.employerId, job.id)}
                      className="text-green-600 hover:text-green-900 mr-4"
                    >
                      <FaSync className="inline mr-1" /> Reactivate
                    </button>
                  )}
                  <button 
                    onClick={() => handleDeleteJob(job.employerId, job.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <FaTrash className="inline mr-1" /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {/* Job Details Modal */}
      {showModal && selectedJob && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center p-6 border-b">
              <h3 className="text-xl font-bold text-blue-950">Job Details</h3>
              <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray-700">
                &times;
              </button>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h4 className="text-lg font-semibold mb-2">{selectedJob.jobTitle}</h4>
                  <p className="text-gray-600 mb-4">{selectedJob.companyName}</p>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Location</h5>
                    <p>{selectedJob.parish}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Work Type</h5>
                    <p>{selectedJob.workType}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Employment Type</h5>
                    <p>{selectedJob.employmentType}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Salary</h5>
                    <p>{selectedJob.salary || 'Not specified'}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Experience Required</h5>
                    <p>{selectedJob.experience || 'Not specified'}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Education Required</h5>
                    <p>{selectedJob.degreeLevel || 'Not specified'}</p>
                  </div>
                </div>
                
                <div>
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Applications</h5>
                    <p>{selectedJob.applications ? 
                       (typeof selectedJob.applications === 'object' ? 
                         Object.keys(selectedJob.applications).length : 
                         selectedJob.applications) : 
                       0}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Created At</h5>
                    <p>{new Date(selectedJob.createdAt).toLocaleString()}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Expiration Date</h5>
                    <p className="text-red-500">
                      {selectedJob.expirationDate ? 
                        `${new Date(selectedJob.expirationDate).toLocaleString()} (Expired ${selectedJob.daysExpired} days ago)` : 
                        'No expiration date set'}
                    </p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Status</h5>
                    <p className={selectedJob.isStatusExpired ? 'text-red-600' : 'text-yellow-600'}>
                      {selectedJob.isStatusExpired ? 'Explicitly marked as expired' : 'Expired by date only'}
                    </p>
                  </div>
                  
                  <div className="mb-4">
                    <h5 className="font-medium text-gray-700">Contact Email</h5>
                    <p>{selectedJob.contactEmail || selectedJob.applicationEmail || 'Not specified'}</p>
                  </div>
                </div>
              </div>
              
              <div className="mt-6">
                <h5 className="font-medium text-gray-700 mb-2">Job Description</h5>
                <div className="border p-4 rounded-lg bg-gray-50 whitespace-pre-line">
                  {selectedJob.description || 'No description provided'}
                </div>
              </div>
              
              <div className="mt-6 flex gap-4 justify-end">
                {!selectedJob.isStatusExpired && (
                  <button 
                    onClick={() => {
                      setShowModal(false);
                      handleMarkAsExpired(selectedJob.employerId, selectedJob.id);
                    }}
                    className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                  >
                    <FaCalendarTimes className="inline mr-2" /> Mark as Expired
                  </button>
                )}
                {selectedJob.isStatusExpired && (
                  <button 
                    onClick={() => {
                      setShowModal(false);
                      handleReactivateJob(selectedJob.employerId, selectedJob.id);
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    <FaSync className="inline mr-2" /> Reactivate Job
                  </button>
                )}
                <button 
                  onClick={() => {
                    setShowModal(false);
                    handleDeleteJob(selectedJob.employerId, selectedJob.id);
                  }}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  <FaTrash className="inline mr-2" /> Delete Job
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpiredJobs;