import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 md:p-8">
          <h1 className="text-2xl md:text-3xl font-bold text-center text-blue-950 mb-4">
            Website Privacy Policy
          </h1>
          
          <div className="text-sm text-gray-600 mb-6">
            This update to our Privacy Policy was posted on September 11, 2020 by Hire Me Jamaica Ltd, a Jamaican limited liability company, referred to herein as "Hire Me". This Policy reflects our intent to comply with Jamaica's Data Protection Act 2017, with respect to information gathered through our Website (www.hiremeja.com) and any related subdomains we may use from time to time. By using our Website, you're agreeing to be bound by this Policy. If you are not satisfied with or do not agree with our Privacy Policy, you should not use our Website.
          </div>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">1. Definitions</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  <span className="font-semibold">"Non-Personal Information" (NPI)</span> is information that is in no way personally identifiable and that is obtained automatically when you access our Website with a web browser.
                </p>
                <p>
                  <span className="font-semibold">"Personally Identifiable Information" (PII)</span> is non-public information that is personally identifiable to you and obtained for us to provide you with a product or service. PII may include information such as your name, address, email address, phone number, location data, an online identifier, and/or one or more factors specific to your physical, physiological, genetic, mental, economic, cultural or social identity.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">2. Our Commitment to Your Privacy</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Our Privacy Policy tells you what PII we may collect from you, how we may process your PII, how you can limit our use of your PII, and your rights to obtain, modify and/or delete any PII we have collected from you. Except as set out below, we do not share, or sell, or disclose to a third party, any information collected through our Website.
                </p>
                <p>
                  Hire Me is committed to the security and privacy of all our customers. We take your privacy seriously, and we will work with you to ensure that you have an enjoyable online experience.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">3. Information You Provide Us</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  We collect personal information when you request information about our services and products, interact with our Website forms/bots or customer service representatives, or otherwise voluntarily provide such information through our Website or otherwise. This information includes the following categories:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Your Personal Contact Information, such as name, phone number and email address</li>
                  <li>Hire Me services and products you may be interested in accessing ranges</li>
                  <li>Your professional profile and/or company information, including company website domain/URL, whether you represent a marketing agency, whether your company is a current customer of certain of our business partners</li>
                </ul>
                <p>Generally, you will have control over the amount and type of information you provide to us when using our Website.</p>
                <p>As a visitor to our Website, you can browse our Website to find out more about us. You are not required to sign in or provide us with any PII as a visitor.</p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">4. Information Collected Automatically</h2>
              <div className="space-y-4 text-gray-700">
                <p>When you use our Website, we automatically collect certain information by the interaction of your mobile device or web browser with our Website.</p>
                
                <h3 className="text-lg font-medium text-blue-900 mt-6">Cookies</h3>
                <p>
                  Like many other websites, we use "Cookies." A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to identify the areas of our Website that you have visited. We also use cookies to enhance your online experience by eliminating the need to log in multiple times for specific content. Finally, we may use Cookies to personalize the content that you see on our Website or to customize marketing and other information we provide to you.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Other Automatically-Gathered Information</h3>
                <p>
                  We may automatically record information when you visit our Website, including the URL, IP address, browser type and language, and the date and time of your visit. We use this information to analyze trends among our users to help improve our Website or customize communications and information that you receive from us. If combined with other information we know about you from previous visits, the data possibly could be used to identify you personally, even if you are not signed in to our Website.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Google Analytics Privacy Policy</h3>
                <p>
                  Our Website uses "Google Analytics" to collect information about the use of our Website. Google Analytics collects information such as how often users visit our site, what pages they visit when they do so, and what other sites they used prior to coming to our site. We use the information we get from Google Analytics to improve our Website, services and products. Google Analytics collects only the IP address assigned to you on the date you visit our site, rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics with personally identifiable information. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit our site, the cookie cannot be used by anyone but Google. Google's ability to use and share information collected by Google Analytics about your visits to our site is restricted by the Google Analytics Terms of Use. You can prevent Google Analytics from recognizing you on return visits to this site by disabling cookies on your browser.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Do Not Track Settings</h3>
                <p>
                  Some web browsers have settings that enable you to request that our Website does not track your movement within our Website. Our Website does not obey such settings when transmitted to and detected by our Website. However, this does not change our compliance in any other way with our Privacy Policy.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">5. How Information is Used</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  When we use or process personal data about you, we do so only as necessary to provide the services and products you use (e.g., to meet our contractual and legal obligations), or otherwise with your consent, to comply with applicable law, or to fulfill other legitimate interests of you or us as described in this Policy. Through our Website, you will be provided with the choice of which types of communications you will receive with us, and the ability to change those choices whenever you want.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Providing Requested Services and Products; Fulfilling Contractual Obligations</h3>
                <p>
                  We may use PII to provide the services and products you've requested or to otherwise perform any contract we may have with you, including communicating with you about the scope of work, payment or other related topics. When you create an account on our Website, buy a product or service from us, or otherwise agree to our terms and conditions, a contract is formed between you and us.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Information We Process with Your Consent</h3>
                <p>
                  Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our Website or ask us to provide you more information about our business, including job opportunities and our products and services, you provide your consent to us to process information that may be PII.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Legally Required Releases of Information</h3>
                <p>
                  We may be legally required to disclose your PII if such disclosure is (a) required by subpoena, law, or other legal process; (b) necessary to assist law enforcement officials or government enforcement agencies; (c) necessary to investigate violations of or otherwise enforce our Legal Terms; (d) necessary to protect us from legal action or claims from third parties including you; and/or (e) necessary to protect the legal rights, personal/real property, or personal safety of our company, customers, third party partners, employees, and affiliates.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">6. Our Security Policy</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Our Website is hosted in Jamaica and we conduct all of our business from our office located in Jamaica. By submitting your personal data from outside Jamaica, you're agreeing to the transfer, storing and processing of your data in Jamaica. Your data may be transferred to countries that do not have the same data protection laws as the country from which you initially provided the information.
                </p>
                <p>
                  We have taken steps to build our Website using sophisticated encryption and authentication tools to protect the security of your PII. When we collect your PII through our Website, we will encrypt your PII before it travels over the Internet using industry standards as establishing for conducting secure online transactions. We also use industry standard technologies such as secure routers and fire walls to make sure that your PII is safe. Unfortunately, we cannot fully guarantee secure data transmission over the Internet because of its nature.
                </p>
                <p>
                  Once we receive your PII, we have industry standard security measures in place to protect against the loss or misuse of your PII, though again, we cannot fully guarantee against such loss or misuse. We strongly urge you to protect any password you may have for our Website and not share it with anyone. You should always log out of our Website when you are done using it, especially if you are sharing a computer with someone else or are using a computer in a public place.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">7. Other Important Policies</h2>
              <div className="space-y-4 text-gray-700">
                <h3 className="text-lg font-medium text-blue-900">Email Policy</h3>
                <p>
                  We try to comply with global anti-spam standards. You can always opt out of receipt of further email correspondence from us or our affiliates. We will not sell, rent, or trade your email address to any unaffiliated third-party without your permission (except in the sale or transfer of our business).
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Other Websites</h3>
                <p>
                  Our Website may contain links to other websites. You understand that these websites are not under our control and are not subject to our Privacy Policy. These websites will likely have their own privacy policies, and we urge you to read those policies before using the website. We have no responsibility for these websites and provide links to these websites solely for your convenience.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Changes to Our Privacy Policy</h3>
                <p>
                  We reserve the right to change this privacy policy at any time. If our company decides to change this Privacy Policy, we will post those changes on our Website so our users and customers are always aware of what information we collect, use, and disclose. In all cases, your continued use of our Website or our services and products after any change to this Privacy Policy will constitute your acceptance of such change.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">Contact Us</h2>
              <div className="text-gray-700">
                <p>If you have any questions about our Privacy Policy, please contact us:</p>
                <p className="mt-2">
                  Email: info@hiremeja.com<br />
                  Address: 20A South Avenue, Kingston 10, Jamaica
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;