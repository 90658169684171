import React from 'react';
import { Link, useLocation, Outlet, Navigate } from 'react-router-dom';
import { FaUser, FaBriefcase, FaFileAlt, FaQuestionCircle, FaUsers, FaHeart, FaFileInvoice } from 'react-icons/fa';

const MENU_ITEMS = [
  {
    path: '/employer/profile',
    icon: FaUser,
    label: 'Profile'
  },
  {
    path: '/employer/questions',
    icon: FaQuestionCircle,
    label: 'Add Pre-Screening Questions'
  },
  {
    path: '/employer/jobs',
    icon: FaBriefcase,
    label: 'Job Board'
  },
  {
    path: '/employer/resumes',
    icon: FaFileAlt,
    label: 'All Candidates'
  },
  
  {
    path: '/employer/applications',
    icon: FaUsers,
    label: 'Applied Candidates'
  },
  {
    path: '/employer/favorites',
    icon: FaHeart,
    label: 'Favorite Candidates'
  },
  // {
  //   path: '/employer/subscription',
  //   icon: FaCrown,
  //   label: 'Subscription'
  // },
  {
    path: '/employer/payments',
    icon: FaFileInvoice,
    label: 'Payment History'
  }
];

const EmployerLayout = () => {
  const location = useLocation();

  // Redirect to profile if landing on /employer
  if (location.pathname === '/employer') {
    return <Navigate to="/employer/profile" replace />;
  }

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1 bg-blue-950">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <h2 className="text-lg font-semibold text-white">Employer Dashboard</h2>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {MENU_ITEMS.map((item) => {
                  const Icon = item.icon;
                  const isActive = location.pathname === item.path;
                  
                  return (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                        isActive
                          ? 'bg-[#cddd3a] text-blue-950'
                          : 'text-white hover:bg-blue-900 hover:text-white'
                      }`}
                    >
                      <Icon
                        className={`mr-3 h-5 w-5 ${
                          isActive ? 'text-blue-950' : 'text-white'
                        }`}
                      />
                      {item.label}
                    </Link>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile navigation */}
      <div className="md:hidden bg-blue-950 w-full">
        <div className="flex justify-around p-4 overflow-x-auto">
          {MENU_ITEMS.map((item) => {
            const Icon = item.icon;
            const isActive = location.pathname === item.path;
            
            return (
              <Link
                key={item.path}
                to={item.path}
                className={`flex flex-col items-center space-y-1 min-w-[80px] ${
                  isActive ? 'text-[#cddd3a]' : 'text-white'
                }`}
              >
                <Icon className="h-6 w-6" />
                <span className="text-xs whitespace-nowrap">{item.label}</span>
              </Link>
            );
          })}
        </div>
      </div>

      {/* Main content */}
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EmployerLayout;