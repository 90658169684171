import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase';
import { Plus, Edit2, AlertCircle, Clock, CheckCircle2 } from 'lucide-react';
import AddEmployerJobs from './AddEmployerJobs';
import EditEmployerJobs from './EditEmployerJobs';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const EmployerJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [pendingJobs, setPendingJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editingJobId, setEditingJobId] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [jobsRemaining, setJobsRemaining] = useState(0);
  const [jobsInCurrentPeriod, setJobsInCurrentPeriod] = useState([]);
  const [activeTab, setActiveTab] = useState('active'); // 'active' or 'pending'

  const auth = getAuth(app);
  const db = getDatabase(app);

  useEffect(() => {
    const fetchJobsAndSubscription = async () => {
      if (!auth.currentUser) return;

      try {
        // Fetch active subscription details
        const subscriptionRef = ref(db, `employers/${auth.currentUser.uid}/subscription`);
        const subscriptionSnapshot = await get(subscriptionRef);
        const subscriptionData = subscriptionSnapshot.val();
        setSubscription(subscriptionData);

        // Fetch approved jobs
        const jobsRef = ref(db, `jobs/${auth.currentUser.uid}`);
        const snapshot = await get(jobsRef);
        
        let jobsList = [];
        if (snapshot.exists()) {
          jobsList = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            ...data
          }));
        }
        
        setJobs(jobsList);

        // Fetch pending jobs
        const pendingJobsRef = ref(db, `pending-jobs/${auth.currentUser.uid}`);
        const pendingSnapshot = await get(pendingJobsRef);
        
        let pendingJobsList = [];
        if (pendingSnapshot.exists()) {
          pendingJobsList = Object.entries(pendingSnapshot.val()).map(([id, data]) => ({
            id,
            ...data,
            status: 'pending'
          }));
        }
        
        setPendingJobs(pendingJobsList);
        
        // Calculate jobs for current subscription period
        if (subscriptionData?.status === 'active' && subscriptionData?.package?.jobPostLimit) {
          const currentSubscriptionStart = new Date(subscriptionData.startDate);
          
          // Include both approved and pending jobs when calculating usage
          const allJobs = [...jobsList, ...pendingJobsList];
          
          // Filter jobs posted during current subscription period
          const currentPeriodJobs = allJobs.filter(job => {
            const jobDate = new Date(job.createdAt);
            return jobDate >= currentSubscriptionStart;
          });

          setJobsInCurrentPeriod(currentPeriodJobs);
          
          const jobLimit = subscriptionData.package.jobPostLimit === -1 
            ? Infinity 
            : subscriptionData.package.jobPostLimit;
            
          setJobsRemaining(jobLimit - currentPeriodJobs.length);
        } else {
          setJobsInCurrentPeriod([]);
          setJobsRemaining(0);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error loading jobs');
      } finally {
        setLoading(false);
      }
    };

    fetchJobsAndSubscription();
  }, [auth.currentUser, db]);

  const handleAddJob = () => {
    if (!subscription || subscription.status !== 'active') {
      toast.warning('You need an active subscription to post jobs');
      return;
    }

    const now = new Date();
    const endDate = new Date(subscription.endDate);
    
    if (now > endDate) {
      toast.warning('Your subscription has expired. Please renew your subscription.');
      return;
    }

    if (jobsRemaining <= 0 && subscription.package.jobPostLimit !== -1) {
      toast.warning('You have reached your job posting limit for this subscription period. Please upgrade your subscription.');
      return;
    }
    
    setShowAddModal(true);
  };

  const handleEditJob = (jobId) => {
    setEditingJobId(jobId);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return 'text-green-600 bg-green-100';
      case 'pending':
        return 'text-yellow-600 bg-yellow-100';
      case 'inactive':
        return 'text-red-600 bg-red-100';
      case 'approved':
        return 'text-blue-600 bg-blue-100';
      default:
        return 'text-gray-600 bg-gray-100';
    }
  };

  const getStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return <CheckCircle2 className="w-4 h-4 mr-1" />;
      case 'pending':
        return <Clock className="w-4 h-4 mr-1" />;
      case 'inactive':
        return <AlertCircle className="w-4 h-4 mr-1" />;
      case 'approved':
        return <CheckCircle2 className="w-4 h-4 mr-1" />;
      default:
        return null;
    }
  };

  const isJobInCurrentPeriod = (job) => {
    if (!subscription?.startDate) return false;
    const jobDate = new Date(job.createdAt);
    const subscriptionStart = new Date(subscription.startDate);
    return jobDate >= subscriptionStart;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-950"></div>
      </div>
    );
  }

  // Determine what to display based on the active tab
  const displayJobs = activeTab === 'active' ? jobs : pendingJobs;
  const allJobsEmpty = jobs.length === 0 && pendingJobs.length === 0;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-blue-950">My Job Postings</h1>
          <div className="mt-1 space-y-1">
            {subscription && subscription.status === 'active' && (
              <>
                <p className="text-sm text-gray-600">
                  Current Package: {subscription.package.name} ({subscription.package.jobPostLimit === -1 ? 'Unlimited' : subscription.package.jobPostLimit} posts)
                </p>
                <p className="text-sm text-gray-600">
                  Jobs Posted in Current Period: {jobsInCurrentPeriod.length} {subscription.package.jobPostLimit !== -1 ? `/ ${subscription.package.jobPostLimit}` : ''}
                </p>
                {subscription.package.jobPostLimit !== -1 && (
                  <p className="text-sm text-gray-600">
                    Jobs Remaining: {jobsRemaining}
                  </p>
                )}
                <p className="text-sm text-gray-600">
                  Subscription Period: {new Date(subscription.startDate).toLocaleDateString()} - {new Date(subscription.endDate).toLocaleDateString()}
                </p>
              </>
            )}
            {(!subscription || subscription.status !== 'active') && (
              <p className="text-sm text-red-600">
                No active subscription. <Link to="/employer/subscription" className="text-blue-600 hover:underline">Get a subscription</Link>
              </p>
            )}
          </div>
        </div>
        <button
          onClick={handleAddJob}
          className="flex items-center px-4 py-2 bg-blue-950 hover:bg-blue-900 text-white rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!subscription || subscription.status !== 'active' || (jobsRemaining <= 0 && subscription.package.jobPostLimit !== -1)}
        >
          <Plus className="w-4 h-4 mr-2" />
          Post New Job
        </button>
      </div>

      {/* Tab Navigation */}
      {!allJobsEmpty && (
        <div className="flex border-b mb-6">
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === 'active'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('active')}
          >
            Active Jobs ({jobs.length})
          </button>
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === 'pending'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('pending')}
          >
            Pending Approval ({pendingJobs.length})
            {pendingJobs.length > 0 && (
              <span className="ml-2 px-2 py-0.5 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                {pendingJobs.length}
              </span>
            )}
          </button>
        </div>
      )}

      {displayJobs.length === 0 ? (
        <div className="bg-white rounded-lg shadow-sm border p-6">
          <div className="flex flex-col items-center justify-center h-64">
            {activeTab === 'active' && allJobsEmpty ? (
              <>
                <AlertCircle className="w-12 h-12 text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900">No Jobs Posted</h3>
                <p className="text-gray-500 text-center mt-2">
                  You haven't posted any jobs yet. Click the 'Post New Job' button to get started.
                </p>
              </>
            ) : activeTab === 'active' ? (
              <>
                <AlertCircle className="w-12 h-12 text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900">No Active Jobs</h3>
                <p className="text-gray-500 text-center mt-2">
                  You don't have any active job listings at the moment.
                </p>
                {pendingJobs.length > 0 && (
                  <button
                    onClick={() => setActiveTab('pending')}
                    className="mt-4 text-blue-600 hover:underline"
                  >
                    View {pendingJobs.length} pending job{pendingJobs.length !== 1 ? 's' : ''} awaiting approval
                  </button>
                )}
              </>
            ) : (
              <>
                <Clock className="w-12 h-12 text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900">No Pending Jobs</h3>
                <p className="text-gray-500 text-center mt-2">
                  You don't have any jobs awaiting approval.
                </p>
                {jobs.length > 0 && (
                  <button
                    onClick={() => setActiveTab('active')}
                    className="mt-4 text-blue-600 hover:underline"
                  >
                    View {jobs.length} active job{jobs.length !== 1 ? 's' : ''}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="grid gap-6">
          {displayJobs.map((job) => (
            <div key={job.id} className="bg-white rounded-lg shadow-sm border overflow-hidden">
              <div className="p-6">
                <div className="flex justify-between items-start">
                  <div className="flex-1">
                    <div className="flex items-center gap-4">
                      <h3 className="text-xl font-semibold text-blue-950">
                        {job.jobTitle}
                      </h3>
                      <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(job.status)} flex items-center`}>
                        {getStatusIcon(job.status)}
                        {job.status === 'pending' ? 'Pending Approval' : job.status}
                      </span>
                      {isJobInCurrentPeriod(job) && (
                        <span className="px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-600">
                          Current Period
                        </span>
                      )}
                    </div>
                    <p className="text-gray-600 mt-1">{job.companyName}</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                      <div>
                        <p className="text-sm text-gray-500">Location</p>
                        <p className="font-medium">{job.parish}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Employment Type</p>
                        <p className="font-medium">{job.employmentType}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Applications</p>
                        <p className="font-medium">
                          {job.status === 'pending' 
                            ? 'N/A (awaiting approval)' 
                            : (typeof job.applications === 'object' 
                              ? Object.keys(job.applications).length 
                              : job.applications || 0)}
                        </p>
                      </div>
                    </div>
                    <p className="text-sm text-gray-500 mt-2">
                      Posted: {new Date(job.createdAt).toLocaleDateString()}
                    </p>
                    
                    {job.status === 'pending' && (
                      <div className="mt-3 p-3 bg-yellow-50 text-yellow-800 rounded-md text-sm">
                        <div className="flex items-start">
                          <Clock className="w-5 h-5 mr-2 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="font-medium">This job is awaiting admin approval</p>
                            <p>Your job posting will be visible to candidates once approved by our team. This typically takes 1-2 business days.</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex gap-2">
                    {activeTab === 'active' && (
                      <button
                        onClick={() => handleEditJob(job.id)}
                        className="p-2 text-gray-600 hover:text-blue-950 hover:bg-gray-100 rounded-md transition-colors"
                      >
                        <Edit2 className="h-4 w-4" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {showAddModal && (
        <AddEmployerJobs
          onClose={() => setShowAddModal(false)}
          jobsRemaining={jobsRemaining}
        />
      )}

      {editingJobId && (
        <EditEmployerJobs
          jobId={editingJobId}
          onClose={() => setEditingJobId(null)}
        />
      )}
    </div>
  );
};

export default EmployerJobs;