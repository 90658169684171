import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCheckCircle, FaExclamationTriangle, FaArrowRight, FaQuestionCircle } from 'react-icons/fa';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const paymentSuccess = location.state?.paymentSuccess;
  const paymentError = location.state?.paymentError;
  const orderId = location.state?.orderId;
  const packageDetails = location.state?.packageDetails;
  const amount = location.state?.amount;
  const errorMessage = location.state?.errorMessage;

  // Redirect if user navigates directly to this page without state
  useEffect(() => {
    if (!location.state) {
      navigate('/pricing');
    }
  }, [location.state, navigate]);

  if (!location.state) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
          <FaQuestionCircle className="text-gray-400 text-5xl mb-4 mx-auto" />
          <h2 className="text-2xl font-bold text-gray-900 mb-4">No Payment Information</h2>
          <p className="text-gray-600 mb-8">
            There is no payment information to display. Please return to pricing.
          </p>
          <button
            onClick={() => navigate('/pricing')}
            className="w-full bg-blue-950 text-white py-3 px-4 rounded-lg hover:bg-blue-900"
          >
            View Pricing
          </button>
        </div>
      </div>
    );
  }

  // Error state
  if (paymentError) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
          <FaExclamationTriangle className="text-yellow-500 text-5xl mb-4 mx-auto" />
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Payment Processing Issue</h2>
          
          {orderId && (
            <div className="mb-4 p-3 bg-gray-50 rounded-lg">
              <p className="text-gray-700 font-medium">Order Reference: {orderId}</p>
            </div>
          )}
          
          <p className="text-gray-600 mb-8">
            Your payment was received, but we encountered an issue while processing it. Our team has been notified.
            {errorMessage && <span className="block mt-2 text-sm text-red-600">{errorMessage}</span>}
          </p>
          
          <div className="space-y-4">
            <button
              onClick={() => navigate('/employer/dashboard')}
              className="w-full bg-blue-950 text-white py-3 px-4 rounded-lg hover:bg-blue-900"
            >
              Return to Dashboard
            </button>
            
            <button
              onClick={() => navigate('/contact')}
              className="w-full bg-gray-100 text-gray-700 py-3 px-4 rounded-lg hover:bg-gray-200"
            >
              Contact Support
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Success state
  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
        <FaCheckCircle className="text-green-500 text-5xl mb-4 mx-auto" />
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Payment Successful!</h2>
        
        {orderId && (
          <div className="mb-4 p-3 bg-gray-50 rounded-lg">
            <p className="text-gray-700 font-medium">Order ID: {orderId}</p>
          </div>
        )}
        
        {packageDetails && (
          <div className="mb-6 p-4 bg-gray-50 rounded-lg">
            <h3 className="font-semibold text-gray-700 mb-2">Package Details</h3>
            <p className="text-gray-600">{packageDetails.name}</p>
            <p className="text-gray-600">
              {amount ? `$${amount.toFixed(2)}` : packageDetails.proRatedPrice ? 
                `$${packageDetails.proRatedPrice}` : 
                `$${packageDetails.price}`}
            </p>
            <p className="text-gray-600">{packageDetails.duration} Days</p>
            {packageDetails.jobPostLimit && (
              <p className="text-gray-600">
                Job Posts: {packageDetails.jobPostLimit === -1 ? 'Unlimited' : packageDetails.jobPostLimit}
              </p>
            )}
          </div>
        )}

        <div className="mb-8 p-4 bg-yellow-50 rounded-lg border border-yellow-100">
          <p className="text-yellow-800">
            Your payment is pending approval. Once approved, your subscription will be activated, 
            and you'll receive a confirmation email.
          </p>
        </div>
        
        <div className="space-y-4">
          <button
            onClick={() => navigate('/employer/subscription')}
            className="w-full bg-blue-950 text-white py-3 px-4 rounded-lg hover:bg-blue-900 flex items-center justify-center space-x-2"
          >
            <span>View Subscription</span>
            <FaArrowRight />
          </button>
          
          <button
            onClick={() => navigate('/employer/dashboard')}
            className="w-full bg-gray-100 text-gray-700 py-3 px-4 rounded-lg hover:bg-gray-200"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;