import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaUserClock, FaCalendarTimes } from 'react-icons/fa';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    totalEmployers: 0,
    totalCandidates: 0,
    approvedJobs: 0,
    totalJobs: 0,
    totalResumes: 0,
    paidAmount: 0,
    totalPackages: 0,
    monthlyPaidAmount: 0,
    privateCandidates: 0,
    candidatesHired: 0,
    expiredEmployers: 0,
    totalRevenue: 0,
    monthlyRevenue: 0,
    privateProfiles: 0,
    totalReviews: 0,
    expiredJobs: 0,
    temporaryWorkers: 0
  });
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const db = getDatabase(app);
  const auth = getAuth(app);

  const fetchStats = useCallback(async () => {
    try {
      const [
        employersSnap, 
        candidatesSnap, 
        jobsSnap, 
        packagesSnap, 
        paymentsSnap,
        reviewsSnap,
        temporaryWorkersSnap,
        pendingJobsSnap
      ] = await Promise.all([
        get(ref(db, 'employers')),
        get(ref(db, 'candidates')),
        get(ref(db, 'jobs')),
        get(ref(db, 'packages')),
        get(ref(db, 'payments')),
        get(ref(db, 'reviews')),
        get(ref(db, 'temporaryWorkers')),
        get(ref(db, 'pending-jobs'))
      ]);

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      let totalApprovedJobs = 0;
      let totalJobsCount = 0;

      // Count approved jobs
      if (jobsSnap.exists()) {
        const jobsData = jobsSnap.val();
        Object.keys(jobsData).forEach(employerId => {
          Object.values(jobsData[employerId]).forEach(job => {
            if (job.status === 'approved' || job.status === 'active') {
              totalApprovedJobs++;
            }
            totalJobsCount++;
          });
        });
      }

      // Add pending jobs to total count
      if (pendingJobsSnap.exists()) {
        const pendingJobsData = pendingJobsSnap.val();
        Object.keys(pendingJobsData).forEach(employerId => {
          Object.values(pendingJobsData[employerId]).forEach(job => {
            if (job.status === 'pending') {
              totalJobsCount++; // Only increment total count, not approved count
            }
          });
        });
      }

      // Count expired jobs
      let totalExpiredJobs = 0;
      if (jobsSnap.exists()) {
        const jobsData = jobsSnap.val();
        const now = new Date();
        
        Object.keys(jobsData).forEach(employerId => {
          Object.values(jobsData[employerId]).forEach(job => {
            // Count jobs with status 'expired' OR jobs with passed expirationDate
            if (
              job.status === 'expired' ||
              (job.expirationDate && new Date(job.expirationDate) < now)
            ) {
              totalExpiredJobs++;
            }
          });
        });
      }

      let totalRevenue = 0;
      let monthlyRevenue = 0;
      if (paymentsSnap.exists()) {
        const payments = Object.entries(paymentsSnap.val()).flatMap(([userId, userPayments]) =>
          Object.entries(userPayments).map(([paymentId, payment]) => payment)
        );

        totalRevenue = payments.reduce((sum, payment) => {
          return sum + (payment.status === 'completed' ? parseFloat(payment.amount) || 0 : 0);
        }, 0);

        monthlyRevenue = payments.reduce((sum, payment) => {
          const paymentDate = new Date(payment.approvedAt || payment.createdAt);
          if (
            payment.status === 'completed' &&
            paymentDate.getMonth() === currentMonth &&
            paymentDate.getFullYear() === currentYear
          ) {
            return sum + (parseFloat(payment.amount) || 0);
          }
          return sum;
        }, 0);
      }

      let totalResumes = 0;
      let privateProfiles = 0;
      let candidatesHired = 0;

      if (candidatesSnap.exists()) {
        const candidates = Object.values(candidatesSnap.val());
        totalResumes = candidates.filter(c => c.profile?.resume).length;
        privateProfiles = candidates.filter(c => !c.isPublic).length;

        candidatesHired = candidates.reduce((count, candidate) => {
          if (candidate.applications) {
            const hasHiredApplication = Object.values(candidate.applications).some(
              application => application.status && application.status.toLowerCase() === 'hired'
            );
            return hasHiredApplication ? count + 1 : count;
          }
          return count;
        }, 0);
      }

      const totalReviews = reviewsSnap.exists() ? Object.keys(reviewsSnap.val()).length : 0;
      const totalTemporaryWorkers = temporaryWorkersSnap.exists() ? Object.keys(temporaryWorkersSnap.val()).length : 0;

      setStats({
        totalEmployers: employersSnap.exists() ? Object.keys(employersSnap.val()).length : 0,
        totalCandidates: candidatesSnap.exists() ? Object.keys(candidatesSnap.val()).length : 0,
        approvedJobs: totalApprovedJobs,
        totalJobs: totalJobsCount,
        totalResumes,
        paidAmount: totalRevenue,
        totalPackages: packagesSnap.exists() ? Object.keys(packagesSnap.val()).length : 0,
        monthlyPaidAmount: monthlyRevenue,
        privateCandidates: privateProfiles,
        candidatesHired,
        expiredEmployers: employersSnap.exists() ? 
          Object.values(employersSnap.val()).filter(e => 
            e.subscription?.endDate && new Date(e.subscription.endDate) < currentDate
          ).length : 0,
        totalRevenue,
        monthlyRevenue,
        privateProfiles,
        totalReviews,
        expiredJobs: totalExpiredJobs,
        temporaryWorkers: totalTemporaryWorkers
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stats:', error);
      toast.error('Error loading dashboard stats');
      setLoading(false);
    }
  }, [db]);

  useEffect(() => {
    const checkAdminAccess = async () => {
      if (!auth.currentUser) {
        navigate('/admin-login');
        return;
      }

      try {
        const adminRef = ref(db, `admins/${auth.currentUser.uid}`);
        const snapshot = await get(adminRef);
        
        if (snapshot.exists()) {
          setIsAdmin(true);
          fetchStats();
        } else {
          toast.error('Access denied. Admin privileges required.');
          navigate('/admin-login');
        }
      } catch (error) {
        console.error('Error checking admin access:', error);
        navigate('/admin-login');
      }
    };

    checkAdminAccess();
  }, [auth.currentUser, db, navigate, fetchStats]);

  if (!isAdmin || loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-950"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6 w-full text-right">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-blue-950">Admin Dashboard</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-blue-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Total Employers</h3>
          <p className="text-3xl font-bold text-blue-950">{stats.totalEmployers}</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Total Candidates</h3>
          <p className="text-3xl font-bold text-green-600">{stats.totalCandidates}</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Approved Jobs</h3>
          <p className="text-3xl font-bold text-yellow-600">{stats.approvedJobs}</p>
        </div>
        <div className="bg-purple-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Total Jobs</h3>
          <p className="text-3xl font-bold text-purple-600">{stats.totalJobs}</p>
        </div>
        <div className="bg-red-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Total Resumes</h3>
          <p className="text-3xl font-bold text-red-600">{stats.totalResumes}</p>
        </div>
        <div className="bg-indigo-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Total Revenue</h3>
          <p className="text-3xl font-bold text-indigo-600">
            ${stats.totalRevenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </p>
        </div>
        <div className="bg-pink-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Total Packages</h3>
          <p className="text-3xl font-bold text-pink-600">{stats.totalPackages}</p>
        </div>
        <div className="bg-orange-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">This Month's Revenue</h3>
          <p className="text-3xl font-bold text-orange-600">
            ${stats.monthlyRevenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </p>
        </div>
        <div className="bg-teal-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Private Candidate Profiles</h3>
          <p className="text-3xl font-bold text-teal-600">{stats.privateProfiles}</p>
        </div>
        <div className="bg-cyan-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Candidates Hired</h3>
          <p className="text-3xl font-bold text-cyan-600">{stats.candidatesHired}</p>
          <p className="text-sm text-cyan-700 mt-1">Successfully placed candidates</p>
        </div>
        <div className="bg-amber-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Expired Subscriptions</h3>
          <p className="text-3xl font-bold text-amber-600">{stats.expiredEmployers}</p>
        </div>
        <div className="bg-slate-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Total Reviews</h3>
          <p className="text-3xl font-bold text-slate-600">{stats.totalReviews}</p>
        </div>
        <Link to="/admin/temporary-workers" className="bg-lime-100 p-4 rounded-lg hover:bg-lime-200 transition-colors">
          <h3 className="text-lg font-semibold mb-2">Temporary Workers</h3>
          <div className="flex items-center justify-between">
            <FaUserClock className="text-3xl text-lime-600" />
            <p className="text-3xl font-bold text-lime-600">{stats.temporaryWorkers}</p>
          </div>
        </Link>
        <Link to="/admin/expired-jobs" className="bg-rose-100 p-4 rounded-lg hover:bg-rose-200 transition-colors">
          <h3 className="text-lg font-semibold mb-2">Expired Jobs</h3>
          <div className="flex items-center justify-between">
            <FaCalendarTimes className="text-3xl text-rose-600" />
            <p className="text-3xl font-bold text-rose-600">{stats.expiredJobs}</p>
          </div>
          <p className="text-sm text-rose-700 mt-1">Jobs past their expiration date</p>
        </Link>
      </div>
    </div>
  );
};

export default AdminDashboard;