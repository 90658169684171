import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { app } from '../../firebase';
import Invoice from '../Invoice';

const Transactions = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const db = getDatabase(app);

  const fetchPayments = useCallback(async () => {
    try {
      const paymentsSnapshot = await get(ref(db, 'payments'));
      const employersSnapshot = await get(ref(db, 'employers'));
      const packagesSnapshot = await get(ref(db, 'packages'));
      
      const paymentsData = [];
      
      if (paymentsSnapshot.exists()) {
        const paymentsVal = paymentsSnapshot.val();
        for (const employerId in paymentsVal) {
          const employerPayments = paymentsVal[employerId];
          const employer = employersSnapshot.val()?.[employerId] || {};
          const profile = employer.profile || {};
          
          for (const paymentId in employerPayments) {
            const paymentData = employerPayments[paymentId];
            const packageData = packagesSnapshot.val()?.[paymentData.packageId] || {};
            
            if (paymentData.status) {
              paymentsData.push({
                id: paymentId,
                employerId,
                ...paymentData,
                employerName: employer.firstName && employer.lastName ? 
                  `${employer.firstName} ${employer.lastName}`.trim() : 
                  employer.email,
                employerEmail: paymentData.email || employer.email,
                packageName: packageData.name || paymentData.packageName,
                packageDetails: packageData,
                date: new Date(paymentData.createdAt),
                profile: {
                  firstName: employer.firstName,
                  lastName: employer.lastName,
                  companyName: profile.companyName,
                  phone: profile.phone,
                  email: profile.email || employer.email
                }
              });
            }
          }
        }
      }
      
      setPayments(paymentsData.sort((a, b) => b.date - a.date));
      setLoading(false);
    } catch (error) {
      console.log("Error fetching payments (handled):", error.message);
      setLoading(false);
    }
  }, [db]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const filterPayments = () => {
    let filtered = [...payments];

    const today = new Date();
    switch (dateRange) {
      case 'today':
        filtered = filtered.filter(t => 
          t.date.toDateString() === today.toDateString()
        );
        break;
      case 'week':
        const weekAgo = new Date(today.setDate(today.getDate() - 7));
        filtered = filtered.filter(t => t.date >= weekAgo);
        break;
      case 'month':
        const monthAgo = new Date(today.setMonth(today.getMonth() - 1));
        filtered = filtered.filter(t => t.date >= monthAgo);
        break;
      default:
        break;
    }

    if (statusFilter !== 'all') {
      filtered = filtered.filter(t => t.status === statusFilter);
    }

    if (searchTerm) {
      filtered = filtered.filter(t =>
        t.employerEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        t.employerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        t.packageName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        t.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        t.orderId?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filtered;
  };

  // Generate an invoice number based on the payment ID and date
  const generateInvoiceNumber = (payment) => {
    // Extract year and month from the payment date
    const date = new Date(payment.createdAt || payment.date);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    
    // Extract a portion of the ID to create a unique number
    let uniqueId = '';
    if (payment.id) {
      // For ORDER prefixed IDs, use the numeric part
      if (payment.id.startsWith('ORDER-')) {
        uniqueId = payment.id.substring(6, 12);
      } 
      // For timestamp IDs, use last 6 digits
      else if (!isNaN(parseInt(payment.id))) {
        uniqueId = payment.id.toString().slice(-6);
      }
      // For other formats, use first 6 chars
      else {
        uniqueId = payment.id.replace(/[^a-zA-Z0-9]/g, '').substring(0, 6);
      }
    }
    
    return `INV-${year}${month}-${uniqueId}`;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-950"></div>
      </div>
    );
  }

  const filteredPayments = filterPayments();

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold text-blue-950 mb-6">Payments</h2>

      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <div className="flex-1">
          <input
            type="text"
            placeholder="Search payments..."
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value="all">All Time</option>
          <option value="today">Today</option>
          <option value="week">This Week</option>
          <option value="month">This Month</option>
        </select>
        <select
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="all">All Status</option>
          <option value="completed">Completed</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Order ID #
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Invoice #
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Employer
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Package
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredPayments.map((payment) => (
              <tr key={payment.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-mono text-gray-900">
                    {payment.orderId || payment.id}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-mono text-gray-900">
                    {generateInvoiceNumber(payment)}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {payment.date.toLocaleDateString()}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900">{payment.employerName}</div>
                  <div className="text-sm text-gray-500">{payment.employerEmail}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{payment.packageName}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    ${parseFloat(payment.amount).toFixed(2)}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    payment.status === 'completed' 
                      ? 'bg-green-100 text-green-800'
                      : payment.status === 'rejected'
                      ? 'bg-red-100 text-red-800' 
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {payment.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {payment.status === "completed" && (
                    <Invoice data={{
                      ...payment,
                      invoiceNumber: generateInvoiceNumber(payment) // Pass invoice number to Invoice component
                    }} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {filteredPayments.length === 0 && (
        <div className="text-center py-4">
          <p className="text-gray-500">No payments found</p>
        </div>
      )}
    </div>
  );
};

export default Transactions;