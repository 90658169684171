import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase';
import { toast } from 'react-toastify';
import { Invoice } from '../Invoice';
import { FaFileInvoice, FaSearch, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';

const PaymentHistory = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });

  const auth = getAuth(app);
  const db = getDatabase(app);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        if (!auth.currentUser) {
          setLoading(false);
          return;
        }

        const userId = auth.currentUser.uid;
        
        // Get all related data
        const [paymentSnap, employerSnap, packagesSnap, checkoutSnap] = await Promise.all([
          get(ref(db, `payments/${userId}`)),
          get(ref(db, `employers/${userId}`)),
          get(ref(db, 'packages')),
          get(ref(db, 'checkoutSessions'))
        ]);

        const employer = employerSnap.val() || {};
        const profile = employer.profile || {};
        const packages = packagesSnap.val() || {};
        const paymentsList = [];

        // Handle regular payments
        if (paymentSnap.exists()) {
          const paymentsData = paymentSnap.val();
          
          for (const paymentId in paymentsData) {
            const paymentData = paymentsData[paymentId];
            const packageInfo = packages[paymentData.packageId] || {};
            
            paymentsList.push({
              id: paymentId,
              date: new Date(paymentData.createdAt),
              packageName: packageInfo.name || paymentData.packageName || 'Unknown Package',
              amount: paymentData.amount,
              paymentMethod: paymentData.paymentMethod,
              status: paymentData.status,
              employerName: `${employer.firstName || ''} ${employer.lastName || ''}`.trim(),
              employerEmail: profile.email || employer.email,
              profile: {
                firstName: employer.firstName,
                lastName: employer.lastName,
                companyName: profile.companyName,
                phone: profile.phone,
                email: profile.email || employer.email
              },
              sourceType: 'payment'
            });
          }
        }

        // Handle checkout sessions
        if (checkoutSnap.exists()) {
          const checkoutSessions = checkoutSnap.val();
          for (const sessionId in checkoutSessions) {
            const session = checkoutSessions[sessionId];
            if (session.userId === userId) {
              const matchingPackage = Object.values(packages).find(
                p => p.price * 100 === session.amount
              );

              paymentsList.push({
                id: sessionId,
                date: new Date(session.createdAt),
                packageName: matchingPackage?.name || 'Package Selection Pending',
                amount: session.amount / 100, // Convert from cents to dollars
                paymentMethod: 'card',
                status: session.status,
                employerName: `${employer.firstName || ''} ${employer.lastName || ''}`.trim(),
                employerEmail: profile.email || employer.email,
                profile: {
                  firstName: employer.firstName,
                  lastName: employer.lastName,
                  companyName: profile.companyName,
                  phone: profile.phone,
                  email: profile.email || employer.email
                },
                sourceType: 'checkout'
              });
            }
          }
        }

        // Sort payments by date, most recent first
        setPayments(paymentsList.sort((a, b) => b.date - a.date));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching payment history:', error);
        toast.error('Error loading payment history');
        setLoading(false);
      }
    };

    fetchPayments();
  }, [auth.currentUser, db]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedPayments = (paymentsToSort) => {
    if (sortConfig.key === null) return paymentsToSort;
    
    return [...paymentsToSort].sort((a, b) => {
      if (sortConfig.key === 'date') {
        return sortConfig.direction === 'asc' 
          ? a.date - b.date 
          : b.date - a.date;
      }
      
      if (sortConfig.key === 'amount') {
        return sortConfig.direction === 'asc' 
          ? parseFloat(a.amount) - parseFloat(b.amount) 
          : parseFloat(b.amount) - parseFloat(a.amount);
      }
      
      if (sortConfig.key === 'status') {
        return sortConfig.direction === 'asc' 
          ? a.status.localeCompare(b.status) 
          : b.status.localeCompare(a.status);
      }

      return 0;
    });
  };

  const filteredPayments = payments.filter(payment => {
    const searchString = searchTerm.toLowerCase();
    return (
      payment.packageName?.toLowerCase().includes(searchString) ||
      payment.date.toLocaleDateString().includes(searchString) ||
      payment.amount?.toString().includes(searchString) ||
      payment.paymentMethod?.toLowerCase().includes(searchString)
    );
  });

  const sortedAndFilteredPayments = getSortedPayments(filteredPayments);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-950"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-blue-950 mb-4">Payment History</h2>
        
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
          <div className="relative w-full md:w-96">
            <input
              type="text"
              placeholder="Search payments..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
          </div>
          
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium text-gray-700">Sort by:</span>
            <div className="flex gap-2">
              <button
                onClick={() => requestSort('date')}
                className={`px-3 py-1.5 text-sm font-medium rounded-md flex items-center ${
                  sortConfig.key === 'date' 
                    ? 'bg-blue-100 text-blue-800 border border-blue-300' 
                    : 'bg-gray-100 text-gray-700 border border-gray-300'
                }`}
              >
                Date
                {sortConfig.key === 'date' && (
                  sortConfig.direction === 'asc' 
                    ? <FaSortAmountUp className="ml-1" /> 
                    : <FaSortAmountDown className="ml-1" />
                )}
              </button>
              <button
                onClick={() => requestSort('amount')}
                className={`px-3 py-1.5 text-sm font-medium rounded-md flex items-center ${
                  sortConfig.key === 'amount' 
                    ? 'bg-blue-100 text-blue-800 border border-blue-300' 
                    : 'bg-gray-100 text-gray-700 border border-gray-300'
                }`}
              >
                Amount
                {sortConfig.key === 'amount' && (
                  sortConfig.direction === 'asc' 
                    ? <FaSortAmountUp className="ml-1" /> 
                    : <FaSortAmountDown className="ml-1" />
                )}
              </button>
              <button
                onClick={() => requestSort('status')}
                className={`px-3 py-1.5 text-sm font-medium rounded-md flex items-center ${
                  sortConfig.key === 'status' 
                    ? 'bg-blue-100 text-blue-800 border border-blue-300' 
                    : 'bg-gray-100 text-gray-700 border border-gray-300'
                }`}
              >
                Status
                {sortConfig.key === 'status' && (
                  sortConfig.direction === 'asc' 
                    ? <FaSortAmountUp className="ml-1" /> 
                    : <FaSortAmountDown className="ml-1" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {sortedAndFilteredPayments.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer"
                  onClick={() => requestSort('date')}
                >
                  <div className="flex items-center">
                    Date
                    {sortConfig.key === 'date' && (
                      sortConfig.direction === 'asc' 
                        ? <FaSortAmountUp className="ml-1" /> 
                        : <FaSortAmountDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Package</th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer"
                  onClick={() => requestSort('amount')}
                >
                  <div className="flex items-center">
                    Amount
                    {sortConfig.key === 'amount' && (
                      sortConfig.direction === 'asc' 
                        ? <FaSortAmountUp className="ml-1" /> 
                        : <FaSortAmountDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Payment Method</th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer"
                  onClick={() => requestSort('status')}
                >
                  <div className="flex items-center">
                    Status
                    {sortConfig.key === 'status' && (
                      sortConfig.direction === 'asc' 
                        ? <FaSortAmountUp className="ml-1" /> 
                        : <FaSortAmountDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Invoice</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedAndFilteredPayments.map((payment, index) => (
                <tr key={`${payment.id}-${payment.sourceType}-${index}`} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {payment.date.toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {payment.packageName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    ${parseFloat(payment.amount).toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {payment.paymentMethod === 'card' ? 'Credit Card' : 
                     payment.paymentMethod === 'bank_transfer' ? 'Bank Transfer' :
                     payment.paymentMethod === 'mobile_money' ? 'Mobile Money' : 
                     'Other'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      payment.status === 'completed' 
                        ? 'bg-green-100 text-green-800'
                        : payment.status === 'rejected' || payment.status === 'failed'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {payment.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {payment.status === 'completed' && (
                      <Invoice data={payment} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center py-8">
          <div className="max-w-sm mx-auto">
            <FaFileInvoice className="mx-auto h-12 w-12 text-gray-300 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              No Payment History
            </h3>
            <p className="text-gray-500 mb-4">
              {searchTerm 
                ? "No payments match your search criteria"
                : "You haven't made any payments yet"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentHistory;