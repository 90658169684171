// Components/Payment/PaymentFailure.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimesCircle, FaRedo, FaHeadset } from 'react-icons/fa';

const PaymentFailure = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
        <FaTimesCircle className="text-red-500 text-5xl mb-4 mx-auto" />
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Payment Failed</h2>
        <p className="text-gray-600 mb-8">
          We couldn't process your payment. This could be due to:
        </p>
        <ul className="text-left text-gray-600 mb-8 list-disc list-inside">
          <li>Insufficient funds</li>
          <li>Invalid card details</li>
          <li>Transaction declined by bank</li>
          <li>Network connectivity issues</li>
        </ul>
        
        <div className="space-y-4">
          <button
            onClick={() => navigate('/checkout')}
            className="w-full bg-blue-950 text-white py-3 px-4 rounded-lg hover:bg-blue-900 flex items-center justify-center space-x-2"
          >
            <FaRedo />
            <span>Try Again</span>
          </button>
          
          <button
            onClick={() => navigate('/support')}
            className="w-full bg-gray-100 text-gray-700 py-3 px-4 rounded-lg hover:bg-gray-200 flex items-center justify-center space-x-2"
          >
            <FaHeadset />
            <span>Contact Support</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure;