import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck, FaTimes, FaCrown, FaBriefcase } from 'react-icons/fa';
import { getDatabase, ref, get, onValue } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { app } from '../firebase';
import pricingBanner from '../assets/pricing-banner.png';
import { toast } from 'react-toastify';

const Hero = () => {
    return (
        <header
            className="max-w-full light text-white bg-center bg-cover bg-fixed"
            style={{
                backgroundImage: `url(${pricingBanner})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
            }}
        >
            <div className="py-32 md:py-32 bg-black bg-opacity-50 dark:bg-opacity-70">
                <div className="container px-4 m-auto">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 text-center">
                            <div className="text-center">
                                <div className="w-3/4 m-auto">
                                    <p style={{ lineHeight: "1.5", color: "#cddd3a" }} className="sm:text-5xl text-center font-extrabold uppercase text-xl">
                                        Removing challenges
                                    </p>
                                    <p style={{ lineHeight: "1.5", color: "#fff" }} className="sm:text-5xl text-center font-bold uppercase text-xl">
                                        So employers can recruit candidates who fit their company culture
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

const NavigationModal = () => {
    const navigate = useNavigate();
    
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg max-w-md w-full mx-4">
                <h3 className="text-2xl font-bold text-blue-950 mb-4">
                    Payment Submitted Successfully!
                </h3>
                <p className="text-gray-600 mb-6">
                    Your payment has been submitted and is pending confirmation. We'll notify you once your subscription is activated.
                </p>
                <button
                    onClick={() => navigate('.', { replace: true, state: {} })}
                    className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

const PricingItem = ({ pricing, currentSubscription }) => {
    const { name, price, duration, features, status, jobPostLimit } = pricing;
    const navigate = useNavigate();
    const auth = getAuth();
    const [user] = useAuthState(auth);

    const isCurrentPlan = currentSubscription?.package?.id === pricing.id;

    const getDaysRemaining = () => {
        if (!currentSubscription?.endDate) return 0;
        const end = new Date(currentSubscription.endDate);
        const now = new Date();
        const diffTime = Math.abs(end - now);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const getPostLimitText = () => {
        if (jobPostLimit === -1) return "Unlimited Job Posts";
        return `${jobPostLimit} Job Post${jobPostLimit !== 1 ? 's' : ''}`;
    };

    const getPackageDescription = () => {
        if (jobPostLimit === -1) return "Perfect for businesses with continuous hiring needs";
        if (jobPostLimit > 1) return `Ideal for posting up to ${jobPostLimit} jobs during the subscription period`;
        return "Perfect for single job posting needs";
    };

    const getButtonText = () => {
        if (!user) return 'Choose Plan';
        if (isCurrentPlan) {
            if (currentSubscription.status === 'pending') return 'Payment Pending';
            return 'Current Plan';
        }
        if (currentSubscription?.status === 'active') return 'Available After Current Plan Expires';
        return 'Select Plan';
    };

    const handleChoosePlan = () => {
        if (user) {
            
            const now = new Date();
            const isSubscriptionExpired = currentSubscription?.endDate && new Date(currentSubscription.endDate) < now;
            
            // Only block if subscription is active AND not expired, or if payment is pending
            if (
                (currentSubscription?.status === 'active' && !isSubscriptionExpired) || 
                (currentSubscription?.status === 'pending' && currentSubscription?.paymentStatus === 'pending')
            ) {
                if (currentSubscription.status === 'active' && !isSubscriptionExpired) {
                    toast.info('You already have an active subscription. Please wait until your current plan expires before purchasing a new one.');
                    navigate('/employer/profile');
                    return;
                } else {
                    toast.info('You have a pending payment. Please complete the payment process.');
                    navigate('/employer/profile');
                    return;
                }
            }
    
            // Allow purchase if no active subscription or if subscription is expired
            navigate('/order-confirmation', { 
                state: { 
                    package: pricing,
                    isUpgrade: false,
                    isDowngrade: false,
                    currentSubscription: null
                }
            });
        } else {
            localStorage.setItem('redirectAfterLogin', '/order-confirmation');
            localStorage.setItem('selectedPackage', JSON.stringify(pricing));
            navigate('/employer-sign-in');
        }
    };

    if (status !== 'active') {
        return null;
    }

    return (
        <div 
            className={`bg-green-50 dark:bg-[#101D2C] p-4 lg:p-12 flex flex-col h-full relative
                ${isCurrentPlan ? 'border-2 border-blue-500' : ''}
            `}
        >
            {isCurrentPlan && (
                <>
                    <div className="absolute top-4 right-4 bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                        {currentSubscription.status === 'pending' ? 'Payment Pending' : 'Current Plan'}
                    </div>
                    <div className="absolute top-4 left-4">
                        <FaCrown className="text-2xl text-yellow-500" />
                    </div>
                </>
            )}
            
            <h3 className="font-bold mb-5 text-3xl text-blue-600">
                {name}
            </h3>

            <div className="mb-3">
                <span className="text-3xl font-bold text-blue-600">
                    USD${price}
                </span>
            </div>

            <div className="space-y-3">
                <span className="font-bold text-xl block">
                    Duration: {duration} Days
                </span>

                <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded-lg text-center flex items-center justify-center gap-2">
                    <FaBriefcase />
                    <span>{getPostLimitText()}</span>
                </div>
            </div>

            <p className="opacity-80 flex-grow mt-4">
                {getPackageDescription()}
            </p>

            <div className="mt-6">
                <ul className="text-gray-600 dark:text-gray-400 space-y-3">
                    <li className="flex items-center font-medium border-b pb-2">
                        <FaCheck className="mr-2 text-blue-600" />
                        <span>{getPostLimitText()}</span>
                    </li>
                    <li className="flex items-center">
                        {features.accessCandidateList ? (
                            <FaCheck className="mr-2 text-blue-600" />
                        ) : (
                            <FaTimes className="mr-2 text-red-300" />
                        )}
                        <span>Access Candidate List</span>
                    </li>
                    <li className="flex items-center">
                        {features.allowJobPosting ? (
                            <FaCheck className="mr-2 text-blue-600" />
                        ) : (
                            <FaTimes className="mr-2 text-red-300" />
                        )}
                        <span>Allow Job Posting</span>
                    </li>
                    <li className="flex items-center">
                        {features.emailBlast ? (
                            <FaCheck className="mr-2 text-blue-600" />
                        ) : (
                            <FaTimes className="mr-2 text-red-300" />
                        )}
                        <span>Email Blast</span>
                    </li>
                    <li className="flex items-center">
                        {features.socialMediaBlast ? (
                            <FaCheck className="mr-2 text-blue-600" />
                        ) : (
                            <FaTimes className="mr-2 text-red-300" />
                        )}
                        <span>Social Media Blast</span>
                    </li>
                    <li className="flex items-center">
                        {features.addPreScreeningQuestions ? (
                            <FaCheck className="mr-2 text-blue-600" />
                        ) : (
                            <FaTimes className="mr-2 text-red-300" />
                        )}
                        <span>Pre-Screening Questions</span>
                    </li>
                </ul>
            </div>

            <button
                onClick={handleChoosePlan}
                disabled={currentSubscription?.status === 'active' || currentSubscription?.status === 'pending'}
                className={`flex items-center justify-center border rounded-md px-7 py-3 w-full mt-6 transition-colors
                    ${isCurrentPlan 
                        ? 'bg-blue-500 text-white hover:bg-blue-600' 
                        : currentSubscription?.status === 'active' || currentSubscription?.status === 'pending'
                          ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                          : 'border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white'
                    }
                `}
            >
                {getButtonText()}
            </button>
            
            {isCurrentPlan && currentSubscription.status === 'active' && (
                <div className="text-center mt-2 space-y-1">
                    <p className="text-sm text-gray-600">
                        Valid until: {new Date(currentSubscription.endDate).toLocaleDateString()}
                    </p>
                    <p className="text-sm text-gray-600">
                        {getDaysRemaining()} days remaining
                    </p>
                </div>
            )}
        </div>
    );
};

const Pricing = () => {
    const [packages, setPackages] = useState([]);
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const db = getDatabase(app);
    const auth = getAuth();
    const [user] = useAuthState(auth);
    const location = useLocation();

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            
            // Use Promise.allSettled to handle potential permission issues
            const results = await Promise.allSettled([
                get(ref(db, 'packages')),
                get(ref(db, 'candidates')),
                user ? get(ref(db, `employers/${user.uid}`)) : Promise.resolve(null)
            ]);
            
            // Process packages data if successful
            if (results[0].status === 'fulfilled' && results[0].value.exists()) {
                const packagesData = Object.entries(results[0].value.val())
                    .map(([id, data]) => ({
                        id,
                        ...data
                    }))
                    .filter(pkg => pkg.status === 'active')
                    .sort((a, b) => a.price - b.price);
                setPackages(packagesData);
            }
    
            // Process candidates data if successful
            if (results[1].status === 'fulfilled' && results[1].value.exists()) {
                const candidatesData = Object.entries(results[1].value.val())
                    .map(([id, data]) => ({
                        id,
                        profile: data.profile || {},
                        isPublic: data.isPublic,
                        status: data.status || data.profileStatus,
                    }))
                    .filter(candidate => {
                        const isActive = (candidate.status === 'active') || (candidate.profileStatus === 'active');
                        const isPublicProfile = candidate.isPublic === true;
                        return candidate.profile && isActive && isPublicProfile;
                    });
                
                setTotalCandidates(candidatesData.length);
            }
    
            // Process employer subscription data if user is logged in and data fetch was successful
            if (user && results[2].status === 'fulfilled' && results[2].value && results[2].value.exists()) {
                const employerData = results[2].value.val();
                if (employerData.subscription) {
                    setCurrentSubscription(employerData.subscription);
                }
                
                try {
                    // Set up subscription listener only if we have permission
                    const subscriptionRef = ref(db, `employers/${user.uid}/subscription`);
                    const unsubscribe = onValue(subscriptionRef, (snapshot) => {
                        if (snapshot.exists()) {
                            setCurrentSubscription(snapshot.val());
                        } else {
                            setCurrentSubscription(null);
                        }
                    }, (error) => {
                        // Silent error handling for subscription listener
                        console.log("Subscription listener error (non-critical):", error.message);
                    });
                    
                    return () => unsubscribe();
                } catch (listenerError) {
                    // Silent handling for listener setup errors
                    console.log("Could not set up subscription listener (non-critical)");
                }
            }
        } catch (error) {
            // Just log to console, no toast
            console.log("Error in data fetching (handled):", error.message);
        } finally {
            // Always set loading to false regardless of success/failure
            setLoading(false);
        }
    }, [db, user]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (location.state?.paymentSuccess) {
            toast.success('Payment submitted successfully! Your subscription will be activated once payment is confirmed.');
        }
    }, [location.state]);

    if (loading && packages.length === 0) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-950"></div>
            </div>
        );
    }

    if (!loading && packages.length === 0) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="text-center">
                    <h2 className="text-2xl font-bold text-gray-700">No packages available at the moment</h2>
                    <p className="text-gray-500 mt-2">Please check back later</p>
                </div>
            </div>
        );
    }

    return (
        <section className="max-w-full">
            <Hero />
            <div className="container px-4 m-auto py-14 md:py-24 bg-white">
                <div className="grid grid-cols-12 mb-12">
                    <div className="col-span-12 text-center">
                        <h2 className="sm:text-3xl text-xl leading-none md:text-[45px] font-bold mb-4" 
                            style={{ color: "#263571" }}>
                            Total of <span style={{ color: "#cddd3a" }}>{totalCandidates}</span> candidate profiles listed
                        </h2>
                        <h2 style={{ color: "#cddd3a" }} 
                            className="sm:text-4xl mt-24 text-center font-bold uppercase text-xl">
                            Corporate Package Plans
                        </h2>
                    </div>
                </div>

                {currentSubscription?.status === 'pending' && (
                    <div className="mb-8 bg-yellow-50 p-4 rounded-lg text-center">
                        <p className="text-lg text-yellow-800">
                            Your payment is pending confirmation. Your subscription will be activated once the payment is verified.
                        </p>
                    </div>
                )}

                {currentSubscription?.status === 'active' && currentSubscription?.paymentStatus === 'confirmed' && (
                    <div className="mb-8 bg-blue-50 p-4 rounded-lg text-center">
                        <div className="flex items-center justify-center space-x-2">
                            <FaCrown className="text-yellow-500" />
                            <p className="text-lg text-blue-900">
                                You have an active subscription with {' '}
                                {currentSubscription.package.jobPostLimit === -1 
                                    ? 'unlimited job posts' 
                                    : `${currentSubscription.package.jobPostLimit} job post${currentSubscription.package.jobPostLimit !== 1 ? 's' : ''}`
                                } available. New subscriptions can be purchased after your current plan expires.
                            </p>
                        </div>
                    </div>
                )}

                <div className="flex flex-wrap -mx-4">
                    {packages.map((pkg, i) => (
                        <div
                            className={`p-4 lg:w-1/3 ${i < packages.length - 1 ? "lg:border-r lg:border-gray-300 lg:dark:border-gray-800" : ""}`}
                            key={pkg.id}
                        >
                            <PricingItem 
                                pricing={pkg} 
                                currentSubscription={currentSubscription}
                            />
                        </div>
                    ))}
                </div>
                
                {!user && (
                    <>
                        <h1 className="text-center mt-10 text-4xl capitalize">
                            <span className="text-[#cddd3a]">Hire faster and grow smarter </span> <br />
                            with our powerful and innovative online platform.
                        </h1>

                        <h1 className="text-center font-bold mt-10 text-2xl text-blue-950">
                            Are you already a Member? <Link className="underline text-blue-600" to="/employer-sign-in">Login Here</Link>
                        </h1>
                    </>
                )}

                {currentSubscription?.status === 'active' && (
                    <div className="mt-8 p-6 rounded-lg bg-yellow-50">
                        <h3 className="text-xl font-semibold text-blue-950 mb-2">Subscription Policy:</h3>
                        <p className="text-gray-700">
                            You can only have one active subscription at a time. To purchase a new subscription, 
                            please wait until your current plan expires on {new Date(currentSubscription.endDate).toLocaleDateString()}.
                        </p>
                    </div>
                )}

                {currentSubscription?.status === 'pending' && (
                    <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-semibold text-blue-950 mb-4">Next Steps:</h3>
                        <ol className="list-decimal list-inside space-y-2 text-gray-700">
                            <li>Complete your bank transfer using the provided details</li>
                            <li>Include your email address as payment reference</li>
                            <li>Your subscription will be activated within 24 hours of payment confirmation</li>
                            <li>Check your subscription status in your profile dashboard</li>
                        </ol>
                        <div className="mt-4 text-sm text-gray-600">
                            For any issues with payment, please contact our support team at support@hiremeja.com
                        </div>
                    </div>
                )}
            </div>

            {location.state?.paymentSuccess && <NavigationModal />}
        </section>
    );
};

export default Pricing;