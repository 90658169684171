import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, set, update } from 'firebase/database';
import { app } from '../../firebase';
import { toast } from 'react-toastify';
import { FaCreditCard, FaSpinner, FaArrowLeft } from 'react-icons/fa';

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [error, setError] = useState(null);
    const [orderId, setOrderId] = useState('');
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTermsError, setShowTermsError] = useState(false);
    const functions = getFunctions();
    const db = getDatabase(app);

    const notifyAdmin = useCallback(async () => {
        if (!orderId || !paymentAmount || !selectedPackage || !user) {
            console.log('[Admin Notification] Missing required data:', {
                hasOrderId: !!orderId,
                hasAmount: !!paymentAmount,
                hasPackage: !!selectedPackage,
                hasUser: !!user
            });
            return;
        }
        
        try {
            const notifyPaymentAdmin = httpsCallable(functions, 'notifyPaymentAdmin');
            
            const notificationData = {
                paymentId: orderId,
                amount: parseFloat(paymentAmount),
                currency: "USD",
                packageId: selectedPackage.id,
                packageName: selectedPackage.name,
                packageDetails: {
                    duration: selectedPackage.duration,
                    jobPostLimit: selectedPackage.jobPostLimit,
                    features: selectedPackage.features || {}
                },
                employerId: user.uid,
                employerEmail: user.email,
                timestamp: new Date().toISOString(),
                paymentMethod: 'card',
                isProratedPayment: !!selectedPackage.proRatedPrice,
                originalPrice: selectedPackage.price,
                finalPrice: selectedPackage.proRatedPrice || selectedPackage.price,
                status: 'completed' // Indicate payment is already completed
            };
            
            console.log('[Admin Notification] Sending notification with data:', notificationData);
            
            const result = await notifyPaymentAdmin(notificationData);
            console.log('[Admin Notification] Notification sent successfully:', result);
            
            return result;
        } catch (error) {
            console.error('[Admin Notification] Failed to send notification:', error);
        }
    }, [orderId, paymentAmount, selectedPackage, user, functions]);

    const sendPaymentConfirmationEmail = useCallback(async (paymentData) => {
        try {
            console.log('[Email] Sending payment confirmation email with data:', paymentData);
            const sendPaymentEmail = httpsCallable(functions, 'sendPaymentConfirmationEmail');
            const result = await sendPaymentEmail(paymentData);
            console.log('[Email] Payment confirmation email sent successfully:', result);
            return result;
        } catch (error) {
            console.error('[Email] Failed to send payment confirmation email:', error);
            throw error;
        }
    }, [functions]);

    const handlePaymentCompletion = useCallback(async (resultIndicator, sessionVersion) => {
        console.log('[PaymentHandler] Starting payment completion flow');
        
        if (!user || !selectedPackage || !orderId || !paymentAmount) {
            console.error('[PaymentHandler] Missing required data:', { 
                hasUser: !!user, 
                hasPackage: !!selectedPackage, 
                orderId, 
                paymentAmount 
            });
            toast.error('Missing required payment data');
            navigate('/pricing');
            return;
        }
        
        setProcessing(true);
        console.log('[PaymentHandler] About to process payment:', { resultIndicator, sessionVersion });
    
        try {
            // 1. Update checkout session
            await update(ref(db, `checkoutSessions/${orderId}`), {
                status: 'processing',
                lastUpdated: new Date().toISOString(),
                resultIndicator,
                sessionVersion
            });
            console.log('[PaymentHandler] Updated checkout session');
    
            const now = new Date();
            const endDate = new Date(now);
            endDate.setDate(endDate.getDate() + selectedPackage.duration);
    
            // 2. Create payment record as completed directly
            await set(ref(db, `payments/${user.uid}/${orderId}`), {
                packageId: selectedPackage.id,
                packageName: selectedPackage.name,
                amount: paymentAmount,
                status: 'completed', // Set to completed directly
                createdAt: now.toISOString(),
                approvedAt: now.toISOString(), // Add approval timestamp immediately
                employerId: user.uid,  
                employerEmail: user.email,
                paymentMethod: 'card',
                orderId: orderId,
                currency: 'USD',
                sagicorResponse: {
                    resultIndicator,
                    sessionVersion,
                    processedAt: now.toISOString()
                }
            });
            console.log('[PaymentHandler] Created payment record as completed');
    
            // 3. Update subscription as active directly
            await update(ref(db, `employers/${user.uid}/subscription`), {
                status: 'active', // Set to active directly
                paymentStatus: 'confirmed', // Set to confirmed directly
                startDate: now.toISOString(),
                endDate: endDate.toISOString(),
                package: selectedPackage
            });
            console.log('[PaymentHandler] Updated subscription as active');
    
            // 4. Complete checkout session
            await update(ref(db, `checkoutSessions/${orderId}`), {
                status: 'completed',
                lastUpdated: now.toISOString()
            });
            console.log('[PaymentHandler] Completed checkout session');
    
            // 5. Send admin notification (not for approval now, just for information)
            try {
                await notifyAdmin();
                console.log('[PaymentHandler] Sent admin notification');
            } catch (notificationError) {
                console.error('[PaymentHandler] Admin notification failed:', notificationError);
                await set(ref(db, `notification_errors/${orderId}`), {
                    error: notificationError.message,
                    timestamp: now.toISOString(),
                    type: 'admin_notification',
                    userId: user.uid
                });
            }
    
            // 6. Send payment confirmation email
            try {
                const paymentData = {
                    orderId: orderId,
                    paymentDate: now.toISOString(),
                    currency: 'USD',
                    originalPrice: selectedPackage.price,
                    finalPrice: selectedPackage.proRatedPrice || selectedPackage.price,
                    isProratedPayment: !!selectedPackage.proRatedPrice,
                    packageDetails: {
                        name: selectedPackage.name,
                        duration: selectedPackage.duration,
                        jobPostLimit: selectedPackage.jobPostLimit,
                        features: selectedPackage.features || {}
                    },
                    employerDetails: {
                        id: user.uid,
                        email: user.email
                    }
                };
                
                await sendPaymentConfirmationEmail(paymentData);
                console.log('[PaymentHandler] Sent payment confirmation email');
            } catch (emailError) {
                console.error('[PaymentHandler] Payment confirmation email failed:', emailError);
                await set(ref(db, `notification_errors/${orderId}_email`), {
                    error: emailError.message,
                    timestamp: now.toISOString(),
                    type: 'payment_confirmation_email',
                    userId: user.uid
                });
            }
    
            toast.success('Payment completed successfully! Your subscription is now active.');
            navigate('/pricing', { 
                state: { 
                    paymentSuccess: true,
                    orderId: orderId,
                    packageDetails: selectedPackage,
                    amount: paymentAmount
                } 
            });
    
        } catch (error) {
            console.error('[PaymentHandler] Error during payment processing:', error);
            
            try {
                await set(ref(db, `payment_errors/${orderId}`), {
                    error: error.message,
                    errorStack: error.stack,
                    timestamp: new Date().toISOString(),
                    userId: user.uid,
                    packageId: selectedPackage.id,
                    sagicorResponse: {
                        resultIndicator,
                        sessionVersion
                    }
                });
                console.log('[PaymentHandler] Recorded error details');
            } catch (loggingError) {
                console.error('[PaymentHandler] Failed to log error:', loggingError);
            }
    
            toast.error('Payment processing failed. Please contact support with reference: ' + orderId);
            navigate('/pricing', { 
                state: { 
                    paymentError: true,
                    orderId: orderId,
                    errorMessage: error.message
                } 
            });
        } finally {
            setProcessing(false);
        }
    }, [db, navigate, notifyAdmin, orderId, paymentAmount, selectedPackage, user, sendPaymentConfirmationEmail]);

    useEffect(() => {
        let scriptElement = null;

        const loadCheckoutScript = () => {
            console.log('[Setup] Starting Sagicor script setup');
        
            window.completeCallback = (resultIndicator, sessionVersion) => {
                console.log('[Sagicor] Complete callback triggered:', { resultIndicator, sessionVersion });
                handlePaymentCompletion(resultIndicator, sessionVersion);
            };
            
            window.errorCallback = (error) => {
                console.log('[Sagicor] Error callback triggered:', error);
                setError(error.message || 'Payment failed');
                toast.error('Payment failed. Please try again.');
                setProcessing(false);
            };
            
            window.cancelCallback = () => {
                console.log('[Sagicor] Cancel callback triggered');
                toast.info('Payment cancelled');
                setProcessing(false);
                navigate('/pricing');
            };
        
            scriptElement = document.createElement('script');
            scriptElement.src = 'https://sagicorbank.gateway.mastercard.com/static/checkout/checkout.min.js';
            scriptElement.async = true;
            scriptElement.dataset.error = 'errorCallback';
            scriptElement.dataset.cancel = 'cancelCallback';
            scriptElement.dataset.complete = 'completeCallback';

            scriptElement.onload = () => {
                console.log('[Setup] Sagicor script loaded successfully');
            };

            scriptElement.onerror = (error) => {
                console.error('[Setup] Error loading Sagicor script:', error);
                setError('Failed to load payment system. Please try again.');
            };
            
            document.head.appendChild(scriptElement);
        };

        if (!user) {
            navigate('/employer-sign-in', { state: { from: location } });
            return;
        }

        if (!location.state?.package) {
            navigate('/pricing');
            return;
        }

        setSelectedPackage(location.state.package);
        setLoading(false);
        loadCheckoutScript();

        return () => {
            if (scriptElement && document.head.contains(scriptElement)) {
                document.head.removeChild(scriptElement);
            }
            delete window.errorCallback;
            delete window.cancelCallback;
            delete window.completeCallback;
        };
    }, [user, location, navigate, handlePaymentCompletion]);

    const handlePaymentSubmission = async () => {
        if (!termsAccepted) {
            setShowTermsError(true);
            return;
        }
        
        try {
            setShowTermsError(false);
            setProcessing(true);
            setError(null);
    
            const newOrderId = `ORDER-${Date.now()}${Math.random().toString(36).substr(2, 9)}`;
            const amount = selectedPackage.proRatedPrice || selectedPackage.price;
            
            setOrderId(newOrderId);
            setPaymentAmount(parseFloat(amount));
    
            console.log('[Payment] Starting payment submission:', { orderId: newOrderId, amount });
    
            const initCheckout = httpsCallable(functions, 'apiInitiateCheckout');
            
            const checkoutData = {
                amount: parseFloat(amount),
                currency: "USD",
                orderId: newOrderId,
                apiOperation: "INITIATE_CHECKOUT",
                interaction: {
                    operation: "PURCHASE",
                    merchant: {
                        name: "HireMeJA"
                    }
                },
                order: {
                    currency: "USD",
                    amount: parseFloat(amount).toFixed(2),
                    id: newOrderId,
                    description: "Package Purchase"
                }
            };
    
            const response = await initCheckout(checkoutData);
            console.log('[Payment] Got checkout session:', response.data);
    
            if (response.data?.result?.success && response.data.result.sessionId) {
                const sessionId = response.data.result.sessionId;
                
                try {
                    console.log('[Payment] Configuring Checkout with session:', sessionId);
                    window.Checkout.configure({
                        session: {
                            id: sessionId
                        }
                    });
                    
                    window.Checkout.showPaymentPage();
                } catch (checkoutError) {
                    console.error('[Payment] Checkout configuration error:', checkoutError);
                    toast.error('Failed to initialize payment session');
                    setProcessing(false);
                }
            }
        } catch (error) {
            console.error('[Payment] Submission error:', error);
            setError(error.message || 'Failed to process payment');
            setProcessing(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <button 
                onClick={() => navigate('/pricing')}
                className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-6"
            >
                <FaArrowLeft className="mr-2" />
                <span>Back to Pricing</span>
            </button>

            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold text-gray-900 mb-8">Checkout</h1>

                {error && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-red-700">{error}</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="grid md:grid-cols-3 gap-8">
                    <div className="md:col-span-2">
                        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
                            <h2 className="text-xl font-semibold text-gray-900 mb-4">Credit/Debit Card Payment</h2>
                            <p className="text-gray-600">
                                Click the "Pay Now" button to securely enter your card details.
                            </p>
                        </div>
                    </div>

                    <div className="md:col-span-1">
                        <div className="bg-white rounded-lg shadow-md p-6 sticky top-6">
                            <h2 className="text-xl font-semibold text-gray-900 mb-4">Order Summary</h2>
                            <div className="space-y-4">
                            <div>
                                <h3 className="font-medium text-gray-900">{selectedPackage.name}</h3>
                                <p className="text-gray-600">{selectedPackage.duration} Days</p>
                            </div>
                            <div className="border-t pt-4">
                                <div className="flex justify-between mb-2">
                                    <span className="text-gray-600">Package Price</span>
                                    <span className="text-gray-900">USD ${selectedPackage.price}</span>
                                </div>
                                {selectedPackage.proRatedPrice && (
                                    <div className="flex justify-between text-blue-600">
                                        <span>Pro-rated Adjustment</span>
                                        <span>-USD ${(selectedPackage.price - selectedPackage.proRatedPrice).toFixed(2)}</span>
                                    </div>
                                )}
                                <div className="flex justify-between font-bold text-lg mt-4 pt-4 border-t">
                                    <span className="text-gray-900">Total</span>
                                    <span className="text-gray-900">
                                        USD ${selectedPackage.proRatedPrice || selectedPackage.price}
                                    </span>
                                </div>
                            </div>
                        </div>

                            <div className="mt-6 mb-4">
                                <label className="flex items-start cursor-pointer">
                                    <input 
                                        type="checkbox" 
                                        className="mt-1 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                        onChange={e => setTermsAccepted(e.target.checked)}
                                        checked={termsAccepted}
                                    />
                                    <span className="ml-2 text-sm text-gray-600">
                                        I agree to the <a href="/terms" target="_blank" className="text-blue-600 hover:underline">Terms and Conditions</a>
                                    </span>
                                </label>
                                {showTermsError && (
                                    <p className="mt-1 text-sm text-red-600">
                                        You must agree to the Terms and Conditions to proceed
                                    </p>
                                )}
                            </div>
                            
                            <button
                                onClick={handlePaymentSubmission}
                                disabled={processing || !termsAccepted}
                                className={`w-full px-4 py-3 rounded-lg text-white font-medium 
                                    flex items-center justify-center space-x-2
                                    ${(processing || !termsAccepted) ? 
                                        'bg-blue-400 cursor-not-allowed' : 
                                        'bg-blue-600 hover:bg-blue-700'}`}
                            >
                                {processing ? (
                                    <>
                                        <FaSpinner className="animate-spin" />
                                        <span>Processing...</span>
                                    </>
                                ) : (
                                    <>
                                        <FaCreditCard className="mr-2" />
                                        <span>Pay Now</span>
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Checkout;