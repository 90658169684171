import React, { useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDatabase, ref, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';

const PaymentCallback = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = getAuth();
    const db = getDatabase();

    const processPayment = useCallback(async () => {
        try {
            const params = new URLSearchParams(location.search);
            const resultIndicator = params.get('resultIndicator');

            if (!resultIndicator) {
                throw new Error('Payment verification failed');
            }

            // Update payment status if needed
            if (auth.currentUser) {
                const paymentRef = ref(db, `payments/${auth.currentUser.uid}`);
                await update(paymentRef, {
                    status: 'completed',
                    completedAt: new Date().toISOString()
                });
            }

            navigate('/payment/success');
            toast.success('Payment processed successfully');
        } catch (error) {
            console.error('Payment verification failed:', error);
            navigate('/payment/failure');
            toast.error('Payment verification failed');
        }
    }, [navigate, location.search, auth, db]);

    useEffect(() => {
        processPayment();
    }, [processPayment]);

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
            <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
                <FaSpinner className="animate-spin text-4xl text-blue-950 mx-auto mb-4" />
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Processing Payment</h2>
                <p className="text-gray-600">
                    Please wait while we verify your payment...
                </p>
            </div>
        </div>
    );
};

export default PaymentCallback;