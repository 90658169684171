import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { app } from '../../firebase';
import { toast } from 'react-toastify';
import { getFunctions, httpsCallable } from 'firebase/functions';

const ToggleSwitch = ({ isChecked, onChange, disabled }) => (
  <div className="flex items-center">
    <label className="relative inline-flex items-center cursor-pointer">
      <input 
        type="checkbox" 
        className="sr-only peer"
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <div className={`
        w-11 h-6 rounded-full peer 
        peer-focus:outline-none peer-focus:ring-4 
        ${isChecked 
          ? 'bg-green-500 peer-focus:ring-green-300' 
          : 'bg-red-500 peer-focus:ring-red-300'
        }
        after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
        after:bg-white after:border-gray-300 after:border after:rounded-full 
        after:h-5 after:w-5 after:transition-all
        ${isChecked ? 'after:translate-x-full' : 'after:translate-x-0'}
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
      `}></div>
    </label>
    <span className={`ml-3 text-sm font-medium ${isChecked ? 'text-green-700' : 'text-red-700'}`}>
      {isChecked ? 'Active (Public)' : 'Inactive (Private)'}
    </span>
  </div>
);

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRole, setSelectedRole] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [expirationFilter, setExpirationFilter] = useState('all');
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const db = getDatabase(app);

  // Function to filter users based on expiration date
  const filterByExpiration = useCallback((user) => {
    if (expirationFilter === 'all') return true;
    if (!user.expirationDate) return expirationFilter === 'no-expiration';
    
    const daysLeft = user.daysUntilExpiration;
    
    switch (expirationFilter) {
      case 'expired':
        return daysLeft < 0;
      case 'today':
        return daysLeft === 0;
      case '1day':
        return daysLeft === 1;
      case '7days':
        return daysLeft > 0 && daysLeft <= 7;
      case '14days':
        return daysLeft > 7 && daysLeft <= 14;
      case '30days':
        return daysLeft > 14 && daysLeft <= 30;
      case '60days':
        return daysLeft > 30 && daysLeft <= 60;
      case '90days':
        return daysLeft > 60 && daysLeft <= 90;
      case 'no-expiration':
        return false; // Already handled above
      default:
        return true;
    }
  }, [expirationFilter]);

  // Filter users based on criteria - memoized to avoid recalculation
  const filteredUsers = useCallback(() => {
    return users.filter(user => {
      const matchesRole = selectedRole === 'all' || user.role === selectedRole;
      const matchesSearch = user.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesExpiration = filterByExpiration(user);
      return matchesRole && matchesSearch && matchesExpiration;
    });
  }, [users, selectedRole, searchTerm, filterByExpiration]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const [employersSnap, candidatesSnap] = await Promise.all([
          get(ref(db, 'employers')),
          get(ref(db, 'candidates'))
        ]);

        let allUsers = [];

        if (employersSnap.exists()) {
          const employers = Object.entries(employersSnap.val()).map(([id, data]) => ({
            id,
            ...data,
            fullName: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
            role: 'employer',
            status: data.status || 'active',
            isPublic: data.isPublic !== false,
            createdAt: data.createdAt || 'N/A',
            expirationDate: data.expirationDate || null,
            daysUntilExpiration: data.expirationDate ? calculateDaysUntilExpiration(data.expirationDate) : null
          }));
          allUsers = [...allUsers, ...employers];
        }

        if (candidatesSnap.exists()) {
          const candidates = Object.entries(candidatesSnap.val()).map(([id, data]) => ({
            id,
            ...data,
            fullName: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
            role: 'candidate',
            status: data.isPublic === false ? 'inactive' : (data.status || 'active'), // Set status based on isPublic
            isPublic: data.isPublic !== false,
            createdAt: data.createdAt || 'N/A',
            expirationDate: data.expirationDate || null,
            daysUntilExpiration: data.expirationDate ? calculateDaysUntilExpiration(data.expirationDate) : null
          }));
          allUsers = [...allUsers, ...candidates];
        }

        setUsers(allUsers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Error loading users');
        setLoading(false);
      }
    };

    fetchUsers();
  }, [db]);

  useEffect(() => {
    // Reset selected users when filters change
    setSelectedUsers({});
    setSelectAll(false);
  }, [selectedRole, searchTerm, expirationFilter]);

  // Update selectAll state when individual selections change
  useEffect(() => {
    const currentFiltered = filteredUsers();
    if (currentFiltered.length > 0) {
      const allSelected = currentFiltered.every(user => selectedUsers[user.id]);
      setSelectAll(allSelected);
    } else {
      setSelectAll(false);
    }
  }, [selectedUsers, filteredUsers]);

  const calculateDaysUntilExpiration = (expirationDate) => {
    if (!expirationDate) return null;
    const expDate = new Date(expirationDate);
    const currentDate = new Date();
    const timeDiff = expDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert ms to days and round up
  };

  const getExpirationLabel = (daysUntilExpiration) => {
    if (daysUntilExpiration === null) return 'No Expiration';
    if (daysUntilExpiration < 0) return 'Expired';
    if (daysUntilExpiration === 0) return 'Expires Today';
    if (daysUntilExpiration === 1) return 'Expires Tomorrow';
    return `Expires in ${daysUntilExpiration} days`;
  };

  const getExpirationColorClass = (daysUntilExpiration) => {
    if (daysUntilExpiration === null) return 'text-gray-600';
    if (daysUntilExpiration < 0) return 'text-red-700 font-bold';
    if (daysUntilExpiration <= 3) return 'text-red-600';
    if (daysUntilExpiration <= 7) return 'text-orange-600';
    if (daysUntilExpiration <= 14) return 'text-yellow-600';
    return 'text-green-600';
  };

  const handleStatusChange = async (userId, role, isActive) => {
    try {
      const newStatus = isActive ? 'active' : 'inactive';
      const userRef = ref(db, `${role}s/${userId}`);
      const updates = {
        status: 'active', // Always keep status as active since it's handled separately
        isPublic: isActive, // Use isPublic to control visibility
        updatedAt: new Date().toISOString()
      };

      await update(userRef, updates);

      // If the user is an employer and status is inactive, update all their jobs to inactive
      if (role === 'employer' && !isActive) {
        const jobsRef = ref(db, `jobs/${userId}`);
        const jobsSnap = await get(jobsRef);
        
        if (jobsSnap.exists()) {
          const jobUpdates = {};
          Object.keys(jobsSnap.val()).forEach(jobId => {
            jobUpdates[`jobs/${userId}/${jobId}/status`] = 'inactive';
          });
          await update(ref(db), jobUpdates);
        }
      }

      toast.success('User status updated successfully');
      
      // Update the local state
      setUsers(prevUsers => prevUsers.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            status: newStatus,
            isPublic: isActive
          };
        }
        return user;
      }));
    } catch (error) {
      console.error('Error updating user status:', error);
      toast.error('Error updating user status');
    }
  };

  const handleSendExpirationReminder = async (userId, role, email) => {
    try {
      console.log("Sending reminder to:", { userId, role, email });
      
      const functions = getFunctions();
      
      // Use the correct function name as defined in your Firebase Functions
      const notifyProfileExpiration = httpsCallable(functions, 'notifyProfileExpiration');
      
      const result = await notifyProfileExpiration({
        userId: userId,
        userRole: role,
        email: email
      });
      
      console.log("Function result:", result.data);
      toast.success(`Expiration reminder sent to ${email}`);
      return true;
    } catch (error) {
      console.error('Error sending expiration reminder:', error);
      // More detailed error logging
      if (error.code) console.error('Error code:', error.code);
      if (error.message) console.error('Error message:', error.message);
      if (error.details) console.error('Error details:', error.details);
      
      toast.error(`Error sending reminder: ${error.message || 'Unknown error'}`);
      return false;
    }
  };

  const handleDeleteUser = async (userId, role) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        // Initialize the Firebase Functions
        const functions = getFunctions();
        const deleteUserFunction = httpsCallable(functions, 'deleteUser');
        
        // Show loading toast
        const loadingToast = toast.loading('Deleting user...');
        
        // Call the Cloud Function to delete the user
        const result = await deleteUserFunction({
          userId: userId,
          userType: role === 'employer' ? 'employer' : 'candidate'
        });
        
        // Handle the result
        if (result.data.success) {
          toast.update(loadingToast, { 
            render: 'User deleted successfully', 
            type: 'success', 
            isLoading: false,
            autoClose: 3000
          });
          
          // Update local state to remove the user
          setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
          
          // Remove from selected users if present
          if (selectedUsers[userId]) {
            const newSelectedUsers = { ...selectedUsers };
            delete newSelectedUsers[userId];
            setSelectedUsers(newSelectedUsers);
          }
        } else {
          // Function executed but reported an error
          toast.update(loadingToast, { 
            render: `Error: ${result.data.message || 'Could not delete user'}`, 
            type: 'error', 
            isLoading: false,
            autoClose: 5000
          });
        }
      } catch (error) {
        // Function execution failed
        console.error('Error deleting user:', error);
        
        // Extract the actual error message from the error object
        const errorMessage = error.message || 'Unknown error occurred';
        toast.error(`Error deleting user: ${errorMessage}`);
      }
    }
  };
  
  const handleDownloadUsers = () => {
    try {
      // Filter users based on current filters
      const dataToExport = filteredUsers().map(user => ({
        Name: user.fullName || 'N/A',
        Email: user.email || 'N/A',
        Role: user.role || 'N/A',
        Status: user.isPublic ? 'Active (Public)' : 'Inactive (Private)',
        CreatedAt: user.createdAt ? formatDate(user.createdAt) : 'N/A',
        ExpirationDate: user.expirationDate ? formatDate(user.expirationDate) : 'N/A',
        DaysUntilExpiration: user.daysUntilExpiration !== null ? user.daysUntilExpiration.toString() : 'N/A'
      }));
      
      // Convert to CSV
      const headers = Object.keys(dataToExport[0]);
      const csvRows = [];
      
      // Add headers
      csvRows.push(headers.join(','));
      
      // Add data rows
      for (const row of dataToExport) {
        const values = headers.map(header => {
          const value = row[header] || '';
          // Escape quotes and wrap in quotes if needed
          return `"${String(value).replace(/"/g, '""')}"`;
        });
        csvRows.push(values.join(','));
      }
      
      // Create a blob and download
      const csvString = csvRows.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `users_${new Date().toISOString().split('T')[0]}.csv`);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success('User list downloaded successfully');
    } catch (error) {
      console.error('Error downloading users:', error);
      toast.error('Error downloading user list');
    }
  };
  
  const formatDate = (dateString) => {
    if (!dateString || dateString === 'N/A') return 'N/A';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'N/A';
      
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const month = months[date.getMonth()];
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear();
      
      return `${month} ${day}, ${year}`;
    } catch (error) {
      return 'N/A';
    }
  };

  // Function to handle selecting/deselecting all users
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    
    const newSelectedUsers = {};
    if (isChecked) {
      // Select all filtered users
      filteredUsers().forEach(user => {
        if (user.expirationDate) { // Only select users with expiration dates
          newSelectedUsers[user.id] = { role: user.role, email: user.email };
        }
      });
    }
    
    setSelectedUsers(newSelectedUsers);
  };

  // Function to handle selecting/deselecting individual user
  const handleSelectUser = (user, isChecked) => {
    const newSelectedUsers = { ...selectedUsers };
    
    if (isChecked) {
      newSelectedUsers[user.id] = { role: user.role, email: user.email };
    } else {
      delete newSelectedUsers[user.id];
    }
    
    setSelectedUsers(newSelectedUsers);
  };

  // Function to send expiration reminders to all selected users
  const handleSendBatchReminders = async () => {
    const selectedUserIds = Object.keys(selectedUsers);
    
    if (selectedUserIds.length === 0) {
      toast.warning('No users selected');
      return;
    }

    setIsSendingEmails(true);
    
    try {
      // Process in batches of 10 to avoid overwhelming the server
      const batchSize = 10;
      const batches = [];
      
      // Create batches
      for (let i = 0; i < selectedUserIds.length; i += batchSize) {
        batches.push(selectedUserIds.slice(i, i + batchSize));
      }
      
      let successCount = 0;
      let failCount = 0;
      
      // Process each batch sequentially
      for (const batch of batches) {
        // Create the promises first with proper reference capturing
        const batchPromises = batch.map(userId => {
          const { role, email } = selectedUsers[userId];
          return async () => {
            try {
              const result = await handleSendExpirationReminder(userId, role, email);
              if (result) {
                successCount++;
                return true;
              } else {
                failCount++;
                return false;
              }
            } catch (error) {
              failCount++;
              return false;
            }
          };
        });
        
        // Execute all the promises
        await Promise.all(batchPromises.map(promiseFn => promiseFn()));
      }
      
      // Show summary toast
      if (successCount > 0 && failCount === 0) {
        toast.success(`Successfully sent ${successCount} expiration reminders`);
      } else if (successCount > 0 && failCount > 0) {
        toast.warning(`Sent ${successCount} reminders, but ${failCount} failed`);
      } else {
        toast.error('Failed to send any expiration reminders');
      }
      
      // Clear selections after sending
      setSelectedUsers({});
      setSelectAll(false);
    } catch (error) {
      console.error('Error sending batch reminders:', error);
      toast.error('Error processing batch reminders');
    } finally {
      setIsSendingEmails(false);
    }
  };

  // Get the actual filtered users for rendering
  const displayUsers = filteredUsers();
  
  // Count selected users
  const selectedCount = Object.keys(selectedUsers).length;

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-950"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold text-blue-950 mb-6">User Management</h2>
      
      {/* Filters and actions */}
      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <div className="flex-1">
          <input
            type="text"
            placeholder="Search by name or email..."
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          <option value="all">All Users</option>
          <option value="employer">Employers</option>
          <option value="candidate">Candidates</option>
        </select>
        <select
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={expirationFilter}
          onChange={(e) => setExpirationFilter(e.target.value)}
        >
          <option value="all">All Expirations</option>
          <option value="expired">Expired</option>
          <option value="today">Expires Today</option>
          <option value="1day">Expires Tomorrow</option>
          <option value="7days">Expires in 7 Days</option>
          <option value="14days">Expires in 14 Days</option>
          <option value="30days">Expires in 30 Days</option>
          <option value="60days">Expires in 60 Days</option>
          <option value="90days">Expires in 90 Days</option>
          <option value="no-expiration">No Expiration</option>
        </select>
      </div>
      
      {/* Actions bar */}
      <div className="flex flex-wrap items-center justify-between gap-3 mb-4">
        <div className="flex items-center">
          <button
            onClick={handleSendBatchReminders}
            disabled={selectedCount === 0 || isSendingEmails}
            className={`px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center ${
              selectedCount > 0 && !isSendingEmails
                ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {isSendingEmails ? (
              <>
                <div className="mr-2 h-4 w-4 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                Sending...
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                Send Reminders ({selectedCount})
              </>
            )}
          </button>
        </div>
        
        <div className="flex items-center">
          <button
            onClick={handleDownloadUsers}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center"
            disabled={displayUsers.length === 0}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            Download Users
          </button>
        </div>
      </div>

      {/* Users Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-2 py-3 text-left">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                </div>
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Role
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created At
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Expiration
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {displayUsers.map((user) => (
              <tr key={user.id} className={selectedUsers[user.id] ? 'bg-blue-50' : ''}>
                <td className="px-2 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={!!selectedUsers[user.id]}
                      onChange={(e) => handleSelectUser(user, e.target.checked)}
                      disabled={!user.expirationDate}
                      className={`h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded ${!user.expirationDate ? 'opacity-50 cursor-not-allowed' : ''}`}
                    />
                  </div>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{user.fullName || 'N/A'}</div>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-500">{user.email}</div>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    user.role === 'employer' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                  }`}>
                    {user.role}
                  </span>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <ToggleSwitch 
                    isChecked={user.status === 'active'}
                    onChange={() => handleStatusChange(user.id, user.role, user.status !== 'active')}
                    disabled={false}
                  />
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-500">{formatDate(user.createdAt)}</div>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  {user.expirationDate ? (
                    <div className="flex flex-col">
                      <div className="text-sm text-gray-500">{formatDate(user.expirationDate)}</div>
                      <div className={`text-sm ${getExpirationColorClass(user.daysUntilExpiration)}`}>
                        {getExpirationLabel(user.daysUntilExpiration)}
                      </div>
                    </div>
                  ) : (
                    <div className="text-sm text-gray-500">No Expiration</div>
                  )}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm font-medium">
                  <div className="flex space-x-2">
                    {user.expirationDate && (
                      <button
                        onClick={() => handleSendExpirationReminder(user.id, user.role, user.email)}
                        className="text-indigo-600 hover:text-indigo-900"
                        title="Send expiration reminder"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                      </button>
                    )}
                    <button
                      onClick={() => handleDeleteUser(user.id, user.role)}
                      className="text-red-600 hover:text-red-900"
                      title="Delete user"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {displayUsers.length === 0 && (
          <div className="text-center py-4">
            <p className="text-gray-500">No users found</p>
          </div>
        )}
      </div>
    );
  };

  export default UserManagement;