import React, { useState, useEffect, useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get, update } from 'firebase/database';
import { app } from '../../firebase';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FaCrown, FaCheck, FaTimes, FaClock } from 'react-icons/fa';

const EMAIL_SERVICE_URL = 'http://34.228.74.248:3001';

const EmployerProfile = () => {
  const [profile, setProfile] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subscription: null,
    allPackages: []
  });
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [activePlan, setActivePlan] = useState(null);

  const auth = getAuth(app);
  const db = getDatabase(app);

  const sendPackageExpirationNotification = async (packageData) => {
    try {
      const response = await fetch(`${EMAIL_SERVICE_URL}/notify-package-expiration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ packageData }),
      });

      if (!response.ok) {
        throw new Error('Failed to send package expiration notification');
      }
    } catch (error) {
      console.error('Error sending package notification:', error);
      throw error;
    }
  };

  const fetchProfile = useCallback(async () => {
    try {
      if (!auth.currentUser) {
        setLoading(false);
        return;
      }

      const profileRef = ref(db, `employers/${auth.currentUser.uid}/profile`);
      const subscriptionRef = ref(db, `employers/${auth.currentUser.uid}/subscription`);
      const packagesRef = ref(db, 'packages');
      const jobsRef = ref(db, 'jobs');
      
      const [profileSnap, subscriptionSnap, packagesSnap, jobsSnap] = await Promise.all([
        get(profileRef),
        get(subscriptionRef),
        get(packagesRef),
        get(jobsRef)
      ]);
      
      let allPackages = [];
      let currentSubscription = null;
      let currentPlan = null;
      let jobsCount = 0;

      // Calculate jobs posted
      if (jobsSnap.exists()) {
        jobsCount = Object.values(jobsSnap.val())
          .filter(job => job.employerId === auth.currentUser.uid)
          .length;
      }

      if (packagesSnap.exists()) {
        allPackages = Object.entries(packagesSnap.val())
          .map(([id, data]) => ({ id, ...data }))
          .filter(pkg => pkg.status === 'active')
          .sort((a, b) => a.price - b.price);
      }

      if (subscriptionSnap.exists()) {
        currentSubscription = subscriptionSnap.val();
        currentPlan = allPackages.find(pkg => pkg.id === currentSubscription.package.id);
        
        // Check for expiration and send notification if needed
        const currentDate = new Date();
        const endDate = new Date(currentSubscription.endDate);
        const daysRemaining = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));

        if ([30, 14, 7, 3, 1].includes(daysRemaining) && !currentSubscription.notificationSent) {
          const employerEmail = profileSnap.exists() ? profileSnap.val().email : auth.currentUser.email;

          try {
            await sendPackageExpirationNotification({
              employerEmail,
              packageName: currentSubscription.package.name,
              expirationDate: currentSubscription.endDate,
              daysRemaining,
              features: currentSubscription.package.features,
              jobPostLimit: currentSubscription.package.jobPostLimit,
              jobsPosted: jobsCount
            });

            // Mark notification as sent
            await update(subscriptionRef, {
              notificationSent: true
            });
          } catch (error) {
            console.error('Error sending expiration notification:', error);
          }
        }

        // Reset notification flag if we're past the threshold days
        if (!Object.values([30, 14, 7, 3, 1]).includes(daysRemaining) && currentSubscription.notificationSent) {
          await update(subscriptionRef, {
            notificationSent: false
          });
        }
      }

      setProfile(prev => ({
        ...prev,
        ...(profileSnap.exists() ? profileSnap.val() : {}),
        subscription: currentSubscription,
        allPackages,
        jobsPosted: jobsCount
      }));

      setActivePlan(currentPlan);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
      toast.error('Error loading profile data');
      setLoading(false);
    }
  }, [auth.currentUser, db]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const profileRef = ref(db, `employers/${auth.currentUser.uid}/profile`);
      const updates = {
        companyName: profile.companyName,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phone: profile.phone,
        updatedAt: new Date().toISOString()
      };
      
      await update(profileRef, updates);
      toast.success('Profile updated successfully');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error updating profile');
    }
  };

  const getDaysRemaining = () => {
    if (!profile.subscription?.endDate) return 0;
    const end = new Date(profile.subscription.endDate);
    const now = new Date();
    const diffTime = Math.abs(end - now);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getSubscriptionStatus = () => {
    if (!profile.subscription) return 'inactive';
    
    // Check if subscription status is explicitly set as 'expired' in the database
    if (profile.subscription.status === 'expired') return 'expired';
    
    const now = new Date();
    const endDate = new Date(profile.subscription.endDate);
    
    // Check if current date is past the end date
    if (now > endDate) return 'expired';
    
    const daysRemaining = getDaysRemaining();
    if (daysRemaining <= 7) return 'expiring';
    return 'active';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-950"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      {/* Subscription Status Banner */}
      {activePlan && (
        <div className={`mb-6 p-4 rounded-lg ${
          getSubscriptionStatus() === 'expired' ? 'bg-red-50 text-red-800' :
          getSubscriptionStatus() === 'expiring' ? 'bg-yellow-50 text-yellow-800' :
          'bg-green-50 text-green-800'
        }`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {getSubscriptionStatus() === 'active' && <FaCheck className="text-green-500" />}
              {getSubscriptionStatus() === 'expiring' && <FaClock className="text-yellow-500" />}
              {getSubscriptionStatus() === 'expired' && <FaTimes className="text-red-500" />}
              <span className="font-medium">
                {getSubscriptionStatus() === 'active' && 'Active Subscription'}
                {getSubscriptionStatus() === 'expiring' && 'Subscription Expiring Soon'}
                {getSubscriptionStatus() === 'expired' && 'Subscription Expired'}
              </span>
            </div>
            <Link
              to="/pricing"
              className="text-sm font-medium hover:underline"
            >
              View Plans
            </Link>
          </div>
        </div>
      )}

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-blue-950">Company Profile</h2>
        <button
          onClick={() => setIsEditing(!isEditing)}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-950 rounded-md hover:bg-[#cddd3a] hover:text-blue-950 transition-colors"
        >
          {isEditing ? 'Cancel' : 'Edit Profile'}
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Company Information */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Company Information</h3>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                value={profile.companyName}
                onChange={handleChange}
                disabled={!isEditing}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
              />
            </div>
          </div>
        </div>

        {/* Personal Information */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Personal Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={profile.firstName}
                onChange={handleChange}
                disabled={!isEditing}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={profile.lastName}
                onChange={handleChange}
                disabled={!isEditing}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={profile.email}
                onChange={handleChange}
                disabled={!isEditing}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                value={profile.phone}
                onChange={handleChange}
                disabled={!isEditing}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
              />
            </div>
          </div>
        </div>

        {/* Subscription Information */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Subscription</h3>
          {profile.subscription ? (
  <div className="space-y-6">
    <div className="bg-blue-50 p-6 rounded-lg">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <FaCrown className="text-2xl text-yellow-500" />
          <div>
            <h4 className="text-lg font-semibold text-blue-950">
              {profile.subscription.package.name}
            </h4>
            {getSubscriptionStatus() !== 'expired' ? (
              <p className="text-sm text-gray-600">
                {getDaysRemaining()} days remaining
              </p>
            ) : (
              <p className="text-sm text-red-600">
                Subscription expired
              </p>
            )}
          </div>
        </div>
        <div className="text-right">
          <p className="text-sm text-gray-600">
            Valid until: {formatDate(profile.subscription.endDate)}
          </p>
        </div>
      </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  {Object.entries(profile.subscription.package.features).map(([feature, enabled]) => (
                    <div key={feature} className="flex items-center space-x-2">
                      {enabled ? (
                        <FaCheck className="text-green-500" />
                      ) : (
                        <FaTimes className="text-red-500" />
                      )}
                      <span className="text-sm text-gray-700">
                        {feature.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-yellow-50 p-4 rounded-lg">
              <p className="text-sm text-yellow-800">No active subscription</p>
              <Link
                to="/pricing"
                className="inline-block mt-2 text-sm text-blue-600 hover:text-blue-800"
              >
                View Available Plans →
              </Link>
            </div>
          )}
        </div>

        {isEditing && (
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-6 py-2 bg-blue-950 text-white rounded-md hover:bg-[#cddd3a] hover:text-blue-950 transition-colors"
            >
              Save Changes
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default EmployerProfile;