import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 md:p-8">
          <h1 className="text-2xl md:text-3xl font-bold text-center text-blue-950 mb-4">
            Terms of Use
          </h1>
          
          <div className="text-gray-700 mb-8">
            <h2 className="text-lg font-semibold mb-4">Understanding your rights and responsibilities as a Hire Me user</h2>
            <p className="mb-4">
              These are the Terms of Use ("Terms") under which all Job Seekers, Employers or other users ("You") may use the Hire Me Sites and the Services (each as defined below). These Terms include the Hire Me Privacy Policy which is incorporated into these Terms by reference.
            </p>
            <p className="mb-4 font-medium">
              These Terms constitute a binding agreement between You and Hire Me Jamaica Ltd ("Hire Me"), and are deemed accepted by You each time that You use or access any Hire Me Site or Hire Me Services. If You do not accept the Terms stated here, do not use the Hire Me Sites and the Hire Me Services.
            </p>
          </div>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">Definitions</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  The Hire Me Sites are defined as any Web sites under Hire Me's control, whether partial or otherwise (including, without limitation, hiremeja.com, and the Web site from which these terms of use were accessed from) and include the Hire Me Services.
                </p>
                <p>
                  The Hire Me Services are defined as the applications and services offered by Hire Me, including an on-line service to post and search human resource profiles or other interface that allows You to access such application (collectively, "Hire Me Services"). The Hire Me Sites also allow users ("Users") to create individual profiles, which may include personal information ("Profiles"), and to make these Profiles, or aspects thereof, public.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">1. Use of Hire Me Content</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Hire Me authorizes You, subject to these Terms, to access and use the Hire Me Sites and the Hire Me Content and to download and print a single copy of the content available on or from the Hire Me Sites solely for Your personal, non-commercial use. The contents of the Hire Me Sites, such as designs, text, graphics, images, video, information, logos, button icons, software, audio files and other Hire Me content (collectively, "Hire Me Content"), are protected under copyright, trademark and other laws.
                </p>
                <p>
                  All Hire Me Content is the property of Hire Me or its licensors. The compilation (meaning the collection, arrangement and assembly) of all content on the Hire Me Sites is the exclusive property of Hire Me and is protected by copyright, trademark, and other laws. Unauthorized use of the Hire Me Content may violate these laws and/or applicable communications regulations and statutes, and is strictly prohibited.
                </p>
                <p>
                  You agree not to sell or modify the Hire Me Content or reproduce, display, publicly perform, distribute, or otherwise use the Hire Me Content in any way for any public or commercial purpose, in connection with products or services that are not those of the Hire Me Sites, in any other manner that is likely to cause confusion among consumers, that disparages or discredits Hire Me or its licensors, that dilutes the strength of Hire Me's or its licensor's property, or that otherwise infringes Hire Me's or its licensor's intellectual property rights.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">2. Use of the Hire Me Services</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Hire Me databases and other features of the Hire Me Sites may be used only by individuals seeking employment and by employers seeking employees.
                </p>
                <p>
                  You are responsible for maintaining the confidentiality of Your account, Profile and passwords, as applicable. You may not share Your password or other account access information with any other party, temporarily or permanently, and You shall be responsible for all uses of Your Hire Me Site registrations and passwords, whether or not authorized by You. You agree to immediately notify Hire Me of any unauthorized use of Your account, Profile, or passwords.
                </p>
                <h3 className="text-lg font-medium text-blue-900 mt-6">All Hire Me Users agree to not:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Transmit, post, distribute, store or destroy material in violation of any applicable law or regulation</li>
                  <li>Take any action that imposes an unreasonable or disproportionately large load on any Hire Me Site's infrastructure</li>
                  <li>Use any device to navigate or search any Hire Me Site other than the tools available on the Site</li>
                  <li>Use any data mining, robots or similar data gathering or extraction methods</li>
                  <li>Violate or attempt to violate the security of any Hire Me Site</li>
                  <li>Forge any TCP/IP packet header or any part of the header information</li>
                  <li>Reverse engineer or decompile any parts of any Hire Me Site</li>
                  <li>Aggregate, copy or duplicate any Hire Me Content</li>
                  <li>Post any resume or Profile on behalf of another party</li>
                  <li>Share login credentials with any third party</li>
                  <li>Post or submit any incomplete, false or inaccurate biographical information</li>
                  <li>Solicit passwords or personally identifiable information from other Users</li>
                  <li>Delete or alter any material posted by any other person or entity</li>
                  <li>Harass, incite harassment or advocate harassment of any group, company, or individual</li>
                  <li>Send unsolicited communications of any kind</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">3. Relationship with Hire Me</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Hire Me merely makes the Site and Hire Me Services available to enable Job Seekers and Employers to find and transact directly with each other. Hire Me does not introduce Job Seekers to Employers, find Projects for Job Seekers, or find Job Seekers for Employers.
                </p>
                <p>
                  You acknowledge, agree, and understand that Hire Me is not a party to the relationship or any dealings between Employer and Job Seeker. Users are solely responsible for:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Ensuring the accuracy and legality of any User Content</li>
                  <li>Determining the suitability of other Users for a Service Contract</li>
                  <li>Negotiating, agreeing to, and executing any terms or conditions of Service Contracts</li>
                  <li>Performing Job Seeker Services</li>
                  <li>Paying for Job Seeker Services</li>
                </ul>
                <p>
                  Hire Me does not make any representations about or guarantee the truth or accuracy of any Job Seeker's or Employer's listings or other User Content on the Site.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">4. Taxes and Benefits</h2>
              <div className="space-y-4 text-gray-700">
                <p>Job Seeker acknowledges and agrees that Job Seeker is solely responsible for:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>All tax liability associated with payments received from Job Seeker's Employers</li>
                  <li>Obtaining any liability, health, workers' compensation, disability, unemployment, or other insurance needed</li>
                  <li>Determining and remitting any applicable value added tax or other taxes</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">5. Contractual Relationships between Users</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  If an Employer and Job Seeker decide to enter into a Service Contract, the Service Contract is a contractual relationship directly between the Employer and Job Seeker. Employer and Job Seeker have complete discretion both with regard to whether to enter into a Service Contract with each other and with regard to the terms of any Service Contract.
                </p>
                <p>
                  You acknowledge, agree, and understand that Hire Me is not a party to any Service Contract, that the formation of a Service Contract between Users will not, under any circumstance, create an employment or other service relationship between Hire Me and any User or a partnership or joint venture between Hire Me and any User.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">6. Hire Me Fees</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Users agree to pay the Hire Me membership fees as prescribed in Hire Me's fee schedule.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">7. Warranty/Disclaimer</h2>
              <div className="space-y-4 text-gray-700">
                <p className="font-medium">
                  YOU AGREE NOT TO RELY ON THE SITE, THE HIRE ME SERVICES, ANY INFORMATION ON THE SITE OR THE CONTINUATION OF THE SITE. THE SITE AND THE HIRE ME SERVICES ARE PROVIDED "AS IS" AND ON AN "AS AVAILABLE" BASIS. HIRE ME MAKES NO REPRESENTATIONS OR WARRANTIES WITH REGARD TO THE SITE, THE HIRE ME SERVICES, WORK PRODUCT, USER CONTENT, OR ANY ACTIVITIES OR ITEMS RELATED TO THIS AGREEMENT OR THE TERMS OF SERVICE.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">8. Limitation of Liability</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Hire Me is not liable for any damages or losses arising out of or in connection with the Terms of Service, including but not limited to:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Your use of or inability to use our Site or Services</li>
                  <li>Delays or disruptions in our Services</li>
                  <li>Viruses or other malicious software</li>
                  <li>Glitches, bugs, errors, or inaccuracies</li>
                  <li>Damage to your hardware device</li>
                  <li>The content, actions, or inactions of third parties</li>
                  <li>A suspension or other action taken with respect to your Account</li>
                </ul>
                <p className="mt-4 font-medium">
                  THE LIABILITY OF HIRE ME, OUR AFFILIATES, OUR LICENSORS, AND OUR THIRD-PARTY SERVICE PROVIDERS TO ANY USER FOR ANY CLAIM WILL NOT EXCEED JMD$5000.00.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">9. Termination</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Either party may terminate this Agreement at any time upon written notice to info@hiremeja.com. Upon termination:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Your right to use the Site and Services is automatically revoked</li>
                  <li>Your Account will be closed</li>
                  <li>You will no longer have access to your Account data</li>
                  <li>Any Service Contracts between Users remain in effect</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">10. General Provisions</h2>
              <div className="space-y-4 text-gray-700">
                <h3 className="text-lg font-medium text-blue-900">Choice of Law</h3>
                <p>
                  The Site Terms of Use, the other Terms of Service, and any Claim will be governed by and construed in accordance with the laws of Jamaica.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Access outside of Jamaica</h3>
                <p>
                  Hire Me makes no representations that the Site is appropriate or available for use outside of Jamaica. Those who access or use the Site from other jurisdictions do so at their own risk.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Modifications</h3>
                <p>
                  No modification or amendment to the Terms of Service will be binding upon Hire Me unless they are agreed in a written instrument signed by a duly authorized representative of Hire Me or posted on the Site by Hire Me.
                </p>

                <h3 className="text-lg font-medium text-blue-900 mt-6">Electronic Communications</h3>
                <p>
                  You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Site.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-blue-950 mb-4">Contact Information</h2>
              <div className="text-gray-700">
                <p>For questions about these Terms of Service, please contact us:</p>
                <p className="mt-2">
                  Email: info@hiremeja.com<br />
                  Address: 20A South Avenue, Kingston 10, Jamaica
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;