import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set, remove, push } from 'firebase/database';
import { app } from '../../firebase';
import { toast } from 'react-toastify';

const PromoCodes = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const [currentCode, setCurrentCode] = useState({
    code: '',
    discountPercentage: '',
    startDate: '',
    endDate: '',
    packageId: '',
    status: 'active'
  });

  const db = getDatabase(app);

  useEffect(() => {
    Promise.all([fetchPromoCodes(), fetchPackages()]);
  }, []);

  const fetchPackages = async () => {
    try {
      const packagesSnap = await get(ref(db, 'packages'));
      if (packagesSnap.exists()) {
        const packagesData = Object.entries(packagesSnap.val())
          .map(([id, data]) => ({
            id,
            ...data
          }))
          .filter(pkg => pkg.status === 'active'); // Only get active packages
        setPackages(packagesData);
      }
    } catch (error) {
      console.error('Error fetching packages:', error);
      toast.error('Error loading packages');
    }
  };

  const fetchPromoCodes = async () => {
    try {
      const codesSnap = await get(ref(db, 'promoCodes'));
      if (codesSnap.exists()) {
        const codesData = Object.entries(codesSnap.val()).map(([id, data]) => ({
          id,
          ...data,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate)
        }));
        setPromoCodes(codesData.sort((a, b) => b.endDate - a.endDate));
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching promo codes:', error);
      toast.error('Error loading promo codes');
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCode(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const resetForm = () => {
    setCurrentCode({
      code: '',
      discountPercentage: '',
      startDate: '',
      endDate: '',
      packageId: '',
      status: 'active'
    });
    setIsEditing(false);
  };

  const validateForm = () => {
    if (!currentCode.code || !currentCode.discountPercentage || !currentCode.startDate || !currentCode.endDate || !currentCode.packageId) {
      toast.error('Please fill in all required fields');
      return false;
    }

    if (currentCode.discountPercentage < 1 || currentCode.discountPercentage > 100) {
      toast.error('Discount percentage must be between 1 and 100');
      return false;
    }

    const startDate = new Date(currentCode.startDate);
    const endDate = new Date(currentCode.endDate);
    const today = new Date();

    if (startDate < today) {
      toast.error('Start date cannot be in the past');
      return false;
    }

    if (endDate <= startDate) {
      toast.error('End date must be after start date');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const promoData = {
        ...currentCode,
        discountPercentage: parseFloat(currentCode.discountPercentage),
        startDate: new Date(currentCode.startDate).toISOString(),
        endDate: new Date(currentCode.endDate).toISOString(),
        createdAt: currentCode.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      if (isEditing && currentCode.id) {
        await set(ref(db, `promoCodes/${currentCode.id}`), promoData);
        toast.success('Promo code updated successfully');
      } else {
        const existingCodesSnap = await get(ref(db, 'promoCodes'));
        const exists = existingCodesSnap.exists() && 
          Object.values(existingCodesSnap.val()).some(
            code => code.code.toLowerCase() === currentCode.code.toLowerCase()
          );
        
        if (exists) {
          toast.error('This promo code already exists');
          return;
        }

        await push(ref(db, 'promoCodes'), promoData);
        toast.success('Promo code created successfully');
      }

      resetForm();
      fetchPromoCodes();
    } catch (error) {
      console.error('Error saving promo code:', error);
      toast.error('Error saving promo code');
    }
  };

  const handleEdit = (code) => {
    setCurrentCode({
      ...code,
      startDate: code.startDate.toISOString().split('T')[0],
      endDate: code.endDate.toISOString().split('T')[0]
    });
    setIsEditing(true);
  };

  const handleDelete = async (codeId) => {
    if (window.confirm('Are you sure you want to delete this promo code?')) {
      try {
        await remove(ref(db, `promoCodes/${codeId}`));
        toast.success('Promo code deleted successfully');
        fetchPromoCodes();
      } catch (error) {
        console.error('Error deleting promo code:', error);
        toast.error('Error deleting promo code');
      }
    }
  };

  const getFilteredCodes = () => {
    return promoCodes.filter(code => {
      if (filterStatus === 'expired') {
        return code.endDate < new Date();
      }
      if (filterStatus === 'active') {
        return code.status === 'active' && code.endDate >= new Date();
      }
      if (filterStatus === 'inactive') {
        return code.status === 'inactive';
      }
      return true;
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-950"></div>
      </div>
    );
  }

  const filteredCodes = getFilteredCodes();

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-blue-950">Promo Codes</h2>
        <button
          onClick={() => setIsEditing(false)}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Create New Promo Code
        </button>
      </div>

      {/* Promo Code Form */}
      <form onSubmit={handleSubmit} className="mb-8 bg-gray-50 p-6 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Promo Code*
            </label>
            <input
              type="text"
              name="code"
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={currentCode.code}
              onChange={handleInputChange}
              placeholder="e.g., HMJ1001"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Discount (%)*
            </label>
            <input
              type="number"
              name="discountPercentage"
              required
              min="1"
              max="100"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={currentCode.discountPercentage}
              onChange={handleInputChange}
              placeholder="Enter percentage (1-100)"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Start Date*
            </label>
            <input
              type="date"
              name="startDate"
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={currentCode.startDate}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              End Date*
            </label>
            <input
              type="date"
              name="endDate"
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={currentCode.endDate}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select Package*
            </label>
            <select
              name="packageId"
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={currentCode.packageId}
              onChange={handleInputChange}
            >
              <option value="">Select a package</option>
              {packages.map(pkg => (
                <option key={pkg.id} value={pkg.id}>
                  {pkg.name} (${pkg.price})
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-4 flex gap-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            {isEditing ? 'Update Promo Code' : 'Create Promo Code'}
          </button>
          <button
            type="button"
            onClick={resetForm}
            className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
          >
            Cancel
          </button>
        </div>
      </form>

      {/* Filter */}
      <div className="mb-6">
        <select
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="all">All Promo Codes</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="expired">Expired</option>
        </select>
      </div>

      {/* Promo Codes Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Code
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Discount
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Package
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Validity Period
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredCodes.map((code) => (
              <tr key={code.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{code.code}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{code.discountPercentage}%</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {packages.find(pkg => pkg.id === code.packageId)?.name || 'Unknown Package'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {code.startDate.toLocaleDateString()} - {code.endDate.toLocaleDateString()}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    code.endDate < new Date()
                      ? 'bg-red-100 text-red-800'
                      : code.startDate > new Date()
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-green-100 text-green-800'
                  }`}>
                    {code.endDate < new Date() 
                      ? 'Expired' 
                      : code.startDate > new Date()
                      ? 'Scheduled'
                      : 'Active'
                    }
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => handleEdit(code)}
                    className="text-blue-600 hover:text-blue-900 mr-4"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(code.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {filteredCodes.length === 0 && (
        <div className="text-center py-4">
          <p className="text-gray-500">No promo codes found</p>
        </div>
      )}
    </div>
  );
};

export default PromoCodes;